import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import { LOCATIONS_FEATURE_KEY } from "../../../../common/models/src/lib/constants/location.constants";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	ILocation,
	ILocationRequestDto,
	LocationsState,
} from "../../../../common/models/src/lib/interfaces/location.interface";
import {
	createLocation,
	deleteLocation,
	fetchLocations,
	updateLocation,
} from "./locations-data-access";

const initialState: LocationsState = {
	data: [],
	loading: false,
	total: 0,
	aggregateResults: null,
	errors: null,
};

export const fetchLocationsAction = createAsyncThunk(
	"locations/fetchLocations",
	async (params: CelerumQueryParams = {}) => {
		return fetchLocations(params);
	},
);

export const createLocationAction = createAsyncThunk(
	"locations/createLocation",
	async (location: ILocationRequestDto) => {
		return createLocation(location);
	},
);

export const updateLocationAction = createAsyncThunk(
	"locations/updateLocation",
	async (location: ILocationRequestDto) => {
		return updateLocation(location);
	},
);

export const deleteLocationAction = createAsyncThunk(
	"locations/deleteLocation",
	async (id: number) => {
		return deleteLocation(id);
	},
);

const locationsSlice = createSlice({
	name: LOCATIONS_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		clearLocationsAction: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchLocationsAction.pending, (state: LocationsState) => {
				state.loading = true;
			})
			.addCase(createLocationAction.pending, (state: LocationsState) => {
				state.loading = true;
			})
			.addCase(updateLocationAction.pending, (state: LocationsState) => {
				state.loading = true;
			})
			.addCase(deleteLocationAction.pending, (state: LocationsState) => {
				state.loading = true;
			})
			/** Fulfilled */
			.addCase(
				fetchLocationsAction.fulfilled,
				(state: LocationsState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults } = action.payload;
					state.data = (data as ILocation[]).map((item) => ({
						...item,
						nameAndAddress: `${item.name} - ${item.address}`,
					}));
					state.total = total;
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			.addCase(
				createLocationAction.fulfilled,
				(state: LocationsState, action: PayloadAction<ILocation>) => {
					const location = {
						...action.payload,
						nameAndAddress: `${action.payload.name} - ${action.payload.address}`,
					};
					state.data = [location, ...state.data];
					state.loading = false;
					state.total += 1;
				},
			)
			.addCase(
				updateLocationAction.fulfilled,
				(state: LocationsState, action: PayloadAction<ILocation>) => {
					const index = state.data.findIndex(
						(location) => location.id === action.payload.id,
					);
					state.data[index] = action.payload;
					state.loading = false;
				},
			)
			.addCase(
				deleteLocationAction.fulfilled,
				(state: LocationsState, action: PayloadAction<number>) => {
					state.data = state.data.filter(
						(location) => location.id !== action.payload,
					);
					state.loading = false;
					state.total -= 1;
				},
			)
			/** Rejected */
			.addCase(fetchLocationsAction.rejected, (state: LocationsState) => {
				state.loading = false;
			})
			.addCase(createLocationAction.rejected, (state: LocationsState) => {
				state.loading = false;
			})
			.addCase(updateLocationAction.rejected, (state: LocationsState) => {
				state.loading = false;
			})
			.addCase(deleteLocationAction.rejected, (state: LocationsState) => {
				state.loading = false;
			});
	},
});

/** Actions */
export const { clearLocationsAction } = locationsSlice.actions;

/** Reducer */
export const locationsReducer = locationsSlice.reducer;

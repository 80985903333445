import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { type InferType, array, date, number, object, string } from "yup";
import { DFlex } from "../display/DFlex";
import {
	GenericForm,
	IDate,
	IMultiCreate,
	ISelect,
	IText,
	ITextArea,
	type LoadOptionsFn,
} from "../forms/GenericForm";
import {
	type TypedGridColumnProps,
	jobApi,
	toDateString,
	toasted,
	useLookupSubcontractors,
	useLookupTrucks,
} from "../helpers";
import { toCell } from "../helpersReact";
import { GenericPage } from "./GenericPage";

const DriverSchema = object({
	id: number().label("ID"),
	firstName: string().required().label("First Name"),
	lastName: string().required().label("Last Name"),
	subcontractorId: number().nullable().label("Subcontractor"),
	licenceExpirationDate: date().label("License Expiration Date"),
	notes: string().label("Notes"),
	emails: array()
		.of(string().email().required().label("Email"))
		.default([])
		.label("Emails"),
	phones: array()
		.of(string().required().label("Phone"))
		.default([])
		.label("Phones"),
	truckId: number().label("Truck"),
});
type DriverFormObject = InferType<typeof DriverSchema>;

type DriverFormProps = {
	defaultValues?: Partial<DriverFormObject>;
	onSubmit: (data: DriverFormObject) => void;
	lookupTrucks: LoadOptionsFn;
	lookupSubcontractors: LoadOptionsFn;
};
const DriverForm = ({
	defaultValues,
	onSubmit,
	lookupTrucks,
	lookupSubcontractors,
}: DriverFormProps) => {
	const form = useForm<DriverFormObject>({
		resolver: yupResolver(DriverSchema),
		defaultValues,
	});
	return (
		<GenericForm
			form={form}
			schema={DriverSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<IText n="firstName" />
					<IText n="lastName" />
					<IDate n="licenceExpirationDate" />
				</div>
				<div>
					<ITextArea n="notes" />
					<IMultiCreate n="emails" />
					<IMultiCreate n="phones" />
					<ISelect n="truckId" l={lookupTrucks} />
					<ISelect n="subcontractorId" l={lookupSubcontractors} />
				</div>
			</DFlex>
		</GenericForm>
	);
};
const DriverFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<DriverFormProps, "onSubmit" | "defaultValues">) => {
	const lookupTrucks = useLookupTrucks();
	const lookupSubcontractors = useLookupSubcontractors();
	return (
		<DriverForm
			defaultValues={defaultValues}
			lookupTrucks={lookupTrucks}
			lookupSubcontractors={lookupSubcontractors}
			onSubmit={async (data) => {
				const { id, ...rest } = data;
				const licenceExpirationDate = dayjs(rest.licenceExpirationDate).format(
					"YYYY-MM-DD",
				);
				const processData = async () => {
					if (id) {
						await jobApi.driver.driverUpdate({
							id,
							...rest,
							licenceExpirationDate,
						});
					} else {
						await jobApi.driver.driverCreate({
							...rest,
							licenceExpirationDate,
						});
					}
					onSubmit(data);
				};
				await toasted(
					processData(),
					id ? "Updating Driver" : "Creating Driver",
				);
			}}
		/>
	);
};

type Driver = DriverFormObject & {
	id: number;
	name: string;
	emailsString: string;
	phonesString: string;
	licenceExpirationDateString: string;
	truckString: string;
	subcontractorString: string;
};
const defaultColumns: TypedGridColumnProps<Driver>[] = [
	{ field: "name", title: "Name" },
	{ field: "subcontractorString", title: "Subcontractor" },
	{
		field: "licenceExpirationDate",
		title: "Licence Expiration Date",
		cell: ({ dataItem }) => toCell(dataItem.licenceExpirationDateString),
	},
	{ field: "notes", title: "Notes" },
	{ field: "emailsString", title: "Emails" },
	{ field: "phonesString", title: "Phones" },
	{ field: "truckString", title: "Truck" },
];
const useFetchData = (): ComponentProps<typeof GenericPage<Driver>>["data"] => {
	const _drivers = useQuery({
		queryKey: ["jobApi.driver.driverList"],
		queryFn: () => jobApi.driver.driverList({}).then((x) => x.data.data),
		initialData: [],
	});
	const drivers = useMemo(
		() =>
			_drivers.data.map((x): Driver => {
				const licenceExpirationDate = dayjs(x.licenceExpirationDate).toDate();
				return {
					id: x.id,
					name: `${x.firstName} ${x.lastName}`,
					firstName: x.firstName,
					lastName: x.lastName,
					subcontractorId: x.subcontractor?.id ?? undefined,
					subcontractorString: x.subcontractor?.name ?? "",
					licenceExpirationDate: licenceExpirationDate,
					licenceExpirationDateString: toDateString(licenceExpirationDate),
					notes: x.notes ?? undefined,
					emails: x.emails,
					emailsString: x.emails.join(", "),
					phones: x.phones,
					phonesString: x.phones.join(", "),
					truckId: x.truck?.id ?? undefined,
					truckString: x.truck?.name ?? "",
				};
			}),
		[_drivers.data],
	);
	return {
		data: drivers,
		retry: _drivers.refetch,
		loading: _drivers.isRefetching,
	};
};
export const DriversPage2 = () => {
	const data = useFetchData();
	const handleDelete = (id: number) =>
		toasted(jobApi.driver.driverDelete(id).then(data.retry), "Deleting Driver");
	const navigate = useNavigate();
	const location = useLocation();
	const getForm = (
		id: number | undefined,
		onSubmit: (data: DriverFormObject) => void,
	) => {
		let defaultValues: Partial<DriverFormObject> = {
			licenceExpirationDate: dayjs().startOf("d").add(1, "y").toDate(),
			emails: [],
			phones: [],
		};
		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};
		return (
			<DriverFormWithDTO onSubmit={onSubmit} defaultValues={defaultValues} />
		);
	};
	const extraActions = useMemo(
		() => [
			{
				name: "View details",
				onClick: (x: Driver) =>
					navigate(`/drivers/${x.id}`, { state: { from: location.pathname } }),
			},
		],
		[navigate, location.pathname],
	);
	return (
		<GenericPage
			pageTitle="Drivers"
			name="Driver"
			data={data}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
			extraActions={extraActions}
		/>
	);
};

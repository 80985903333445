import { SvgIcon } from "@progress/kendo-react-common";
import type { ReactNode } from "react";
import {
	dangerIcon,
	errorActionIcon,
	successActionIcon,
	warningIcon,
} from "../../../../../models/src/lib/constants/icon.constants";
import {
	ModalSize,
	ModalType,
} from "../../../../../models/src/lib/enums/modal.enums";
import {
	CelerumConfirmationButton,
	CelerumConfirmationDeleteButton,
	CelerumWhiteButton,
} from "../celerum-buttons/celerum-buttons.component";
import { CelerumModal } from "../celerum-modal/celerum-modal.component";
import styles from "./celerum-confirm-modal.module.scss";

interface CelerumConfirmModalProps {
	isOpen: boolean;
	handleSubmit: () => void;
	handleClose: () => void;
	subtitle?: ReactNode;
	type?: ModalType;
	title?: string | null;
	entityName?: string;
	entityProperty?: string;
	entityValue?: string;
	itemName?: string;
	children?: React.ReactNode;
	size?: ModalSize;
	showButtons?: boolean;
	showIcon?: boolean;
	showSubmitButton?: boolean;
	cancelButtonName?: string;
	submitButtonDisabled?: boolean;
}

export const CelerumConfirmModal = ({
	isOpen,
	title,
	type = ModalType.Delete,
	handleClose,
	handleSubmit,
	entityName,
	entityProperty,
	entityValue,
	subtitle,
	children,
	size = ModalSize.Small,
	showSubmitButton = true,
	cancelButtonName,
	submitButtonDisabled = false,
	showButtons = true,
	showIcon = true,
}: CelerumConfirmModalProps) => {
	const onSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		handleSubmit();
		handleClose();
	};

	const renderIcon = () => {
		switch (type) {
			case ModalType.Delete:
				return dangerIcon;
			case ModalType.Success:
				return successActionIcon;
			case ModalType.Warning:
				return warningIcon;
			case ModalType.Error:
				return errorActionIcon;
			default:
				return;
		}
	};

	return (
		<CelerumModal width={size} toggleDialog={handleClose} visible={isOpen}>
			<div className={styles.modalContainer}>
				{showIcon && (
					<div className={styles.dangerIconContainer}>
						<SvgIcon width={75} icon={renderIcon()} />
					</div>
				)}
				{type === ModalType.Delete ? (
					<h1 className={styles.title}>
						<span>Are you sure you want to delete</span>
						<span className={styles.itemName}>{entityName}</span>
						<span>{entityProperty}</span>
						<span className={styles.itemName}>{entityValue}</span>?
					</h1>
				) : title ? (
					<h1 className={styles.title}>{title}</h1>
				) : (
					<h1 className={styles.title}>Are you sure?</h1>
				)}
				{subtitle && <p className={styles.subtitle}>{subtitle}</p>}
				{children}
				{showButtons && (
					<div className={styles.buttonsContainer}>
						<CelerumWhiteButton
							title={cancelButtonName ?? "No"}
							style={{ width: "100px" }}
							onClick={(event) => {
								event.stopPropagation();
								handleClose();
							}}
						/>
						{showSubmitButton && (
							<div>
								{type === ModalType.Delete ? (
									<CelerumConfirmationDeleteButton
										onClick={onSubmit}
										disabled={submitButtonDisabled}
										title="Yes"
										style={{ width: "100px" }}
									/>
								) : (
									<CelerumConfirmationButton
										onClick={onSubmit}
										disabled={submitButtonDisabled}
										title="Yes"
										style={{ width: "100px" }}
									/>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		</CelerumModal>
	);
};

import NProgress from "nprogress";
import { useEffect, useState } from "react";
import { CONSTRAINTS_GRID_COLUMNS } from "../../../../common/models/src/lib/constants/grid-column.constants";
import {
	ModalSize,
	ModalType,
} from "../../../../common/models/src/lib/enums/modal.enums";
import type { IBase } from "../../../../common/models/src/lib/interfaces/base.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumGridHeader } from "../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumModal } from "../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import {
	clearConstraintsAction,
	deleteConstraintAction,
	fetchConstraintsAction,
} from "../../../data-access/src/lib/constraints.slice";
import { ConstraintsForm } from "./components/constraints-form/constraints-form";

export const ConstraintsFeature = () => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { data, total, loading } = useAppSelector((state) => state.constraints);

	const [showModal, setShowModal] = useState<{
		createOrUpdate: boolean;
		delete: boolean;
	}>({
		createOrUpdate: false,
		delete: false,
	});
	const [selectedConstraint, setSelectedConstraint] = useState<
		IBase | undefined
	>(undefined);

	const openCreateModal = () => {
		setShowModal({ ...showModal, createOrUpdate: true });
	};

	const openUpdateModal = (constraint: IBase) => {
		setShowModal({ ...showModal, createOrUpdate: true });
		setSelectedConstraint(constraint);
	};

	const openDeleteModal = (constraint: IBase) => {
		setShowModal({ ...showModal, delete: true });
		setSelectedConstraint(constraint);
	};

	const closeModal = () => {
		setShowModal({ createOrUpdate: false, delete: false });
		setSelectedConstraint(undefined);
	};

	const requestSortedData = (sort: string) => {
		dispatchWithNotifications({
			action: fetchConstraintsAction,
			payload: { sort },
			errorMessage: "Could not fetch constraints.",
		});
	};

	const handleDeleteTrailer = () => {
		if (!selectedConstraint) return;

		dispatchWithNotifications({
			action: deleteConstraintAction,
			payload: selectedConstraint.id,
			successMessage: `Constraint named ${selectedConstraint.name} deleted successfully.`,
			errorMessage: `Could not delete constraint named ${selectedConstraint.name}.`,
		});
		setSelectedConstraint(undefined);
	};

	useEffect(() => {
		if (loading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading]);

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchConstraintsAction,
			payload: {},
			errorMessage: "Could not fetch constraints.",
		});

		return () => {
			dispatch(clearConstraintsAction());
		};
	}, [dispatch, dispatchWithNotifications]);

	return (
		<>
			<CelerumGridHeader
				title="Constraints"
				numberOfItems={total}
				addButtonName="Add Constraint"
				addButtonDisabled={loading}
				handleOpenAddModal={openCreateModal}
			/>
			<CelerumGrid
				columns={CONSTRAINTS_GRID_COLUMNS}
				data={data}
				total={total}
				handleUpdate={openUpdateModal}
				handleDelete={openDeleteModal}
				requestSortedData={requestSortedData}
			/>
			<CelerumModal
				title={
					selectedConstraint ? selectedConstraint.name : "Add New Constraint"
				}
				width={ModalSize.Small}
				toggleDialog={closeModal}
				visible={showModal.createOrUpdate}
			>
				<ConstraintsForm formState={selectedConstraint} onClose={closeModal} />
			</CelerumModal>
			<CelerumConfirmModal
				type={ModalType.Delete}
				subtitle="The constraint will be completely removed and cannot be recovered."
				entityName="constraint"
				entityProperty="named"
				entityValue={`${selectedConstraint?.name}`}
				isOpen={showModal.delete}
				handleSubmit={handleDeleteTrailer}
				handleClose={closeModal}
			/>
		</>
	);
};

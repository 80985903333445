import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import { TRUCK_TYPES_FEATURE_KEY } from "../../../../common/models/src/lib/constants/truck-types.constants";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	ITruckType,
	TruckTypesState,
} from "../../../../common/models/src/lib/interfaces/truck-type.interface";
import {
	createTruckType,
	deleteTruckType,
	fetchTruckTypes,
	updateTruckType,
} from "./truck-types-data-access";

const initialState: TruckTypesState = {
	data: [],
	total: 0,
	aggregateResults: null,
	loading: false,
	errors: null,
};

export const fetchTruckTypesAction = createAsyncThunk(
	"truckTypes/fetchTruckTypes",
	(params: CelerumQueryParams = {}) => {
		return fetchTruckTypes(params);
	},
);

export const createTruckTypeAction = createAsyncThunk(
	"truckTypes/createTruckType",
	(truckType: ITruckType) => {
		return createTruckType(truckType);
	},
);

export const updateTruckTypeAction = createAsyncThunk(
	"truckTypes/updateTruckType",
	(truckType: ITruckType) => {
		return updateTruckType(truckType);
	},
);

export const deleteTruckTypeAction = createAsyncThunk(
	"truckTypes/deleteTruckType",
	(id: number) => {
		return deleteTruckType(id);
	},
);

const truckTypesSlice = createSlice({
	name: TRUCK_TYPES_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		clearTruckTypesAction: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchTruckTypesAction.pending, (state: TruckTypesState) => {
				state.loading = true;
			})
			.addCase(deleteTruckTypeAction.pending, (state: TruckTypesState) => {
				state.loading = true;
			})
			.addCase(createTruckTypeAction.pending, (state: TruckTypesState) => {
				state.loading = true;
			})
			.addCase(updateTruckTypeAction.pending, (state: TruckTypesState) => {
				state.loading = true;
			})
			/** Fulfilled */
			.addCase(
				fetchTruckTypesAction.fulfilled,
				(state: TruckTypesState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults } = action.payload;
					state.data = (data as ITruckType[]).map((truckType) => ({
						...truckType,
						vehicleType: "TRUCK",
						uniqueId: `${truckType.id}-${truckType.name}`,
					}));
					state.total = total;
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			.addCase(
				createTruckTypeAction.fulfilled,
				(state: TruckTypesState, action: PayloadAction<ITruckType>) => {
					state.data = [action.payload, ...state.data];
					state.loading = false;
					state.total += 1;
				},
			)
			.addCase(
				updateTruckTypeAction.fulfilled,
				(state: TruckTypesState, action: PayloadAction<ITruckType>) => {
					const index = state.data.findIndex(
						(truckType) => truckType.id === action.payload.id,
					);
					state.data[index] = action.payload;
					state.loading = false;
				},
			)
			.addCase(
				deleteTruckTypeAction.fulfilled,
				(state: TruckTypesState, action: PayloadAction<number | null>) => {
					state.data = state.data.filter(
						(truckType) => truckType.id !== action.payload,
					);
					state.loading = false;
					state.total -= 1;
				},
			)
			/** Rejected */
			.addCase(fetchTruckTypesAction.rejected, (state: TruckTypesState) => {
				state.loading = false;
			})
			.addCase(createTruckTypeAction.rejected, (state: TruckTypesState) => {
				state.loading = false;
			})
			.addCase(updateTruckTypeAction.rejected, (state: TruckTypesState) => {
				state.loading = false;
			})
			.addCase(deleteTruckTypeAction.rejected, (state: TruckTypesState) => {
				state.loading = false;
			});
	},
});

/** Actions */
export const { clearTruckTypesAction } = truckTypesSlice.actions;

/** Reducer */
export const truckTypesReducer = truckTypesSlice.reducer;

import { useEffect, useMemo, useState } from "react";
import {
	addAttachmentsAction,
	deleteAttachmentAction,
	fetchAttachmentUriAction,
} from "../../../../../attachments/data-access/src/lib/attachments.slice";
import { editGridIconWhite } from "../../../../../common/models/src/lib/constants/icon.constants";
import { AttachmentUsage } from "../../../../../common/models/src/lib/enums/attachment.enum";
import { ModalSize } from "../../../../../common/models/src/lib/enums/modal.enums";
import type { IEntityAttachmentDto } from "../../../../../common/models/src/lib/interfaces/attachment.interface";
import type { ILoadDetails } from "../../../../../common/models/src/lib/interfaces/load.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumViewDetailsButton } from "../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumCustomCard } from "../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { CelerumDocumentsForm } from "../../../../../common/ui/src/lib/components/celerum-documents-form/celerum-documents-form.component";
import { CelerumLoader } from "../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { CelerumModal } from "../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { fetchLoadTypesAction } from "../../../../data-access/src/lib/load-types.slice";
import {
	attachDocumentsToLoadAction,
	deleteDocumentFromLoadAction,
} from "../../../../data-access/src/lib/loads.slice";
import { EditLoadInformationForm } from "../components/edit-load-information-form/edit-load-information-form.component";
import { LoadInformationCardContainer } from "../components/load-information-card-container/load-information-card-container.component";
import styles from "./load-information-feature.module.scss";

interface LoadInformationFeatureProps {
	className: string | undefined;
}

export const LoadInformationFeature = ({
	className,
}: LoadInformationFeatureProps) => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { load, loading } = useAppSelector((state) => state.loads);
	const { users } = useAppSelector((state) => state.authentication);

	const [showModal, setShowModal] = useState<{
		documents: boolean;
		update: boolean;
	}>({
		documents: false,
		update: false,
	});
	const [modalSize, setModalSize] = useState<ModalSize>(ModalSize.Large);

	const renderedLoadDetails = useMemo(() => {
		if (load) {
			const operator = users.find((user) => user.id === load.assignedTo);

			return {
				...load,
				startDate: new Date(load.startDate),
				endDate: load.endDate && new Date(load.endDate),
				assignedUser: operator,
			};
		}
	}, [load, users]) as ILoadDetails;

	const closeModal = () => {
		setShowModal({ documents: false, update: false });
	};

	const handleAddAttachments = async (formState: IEntityAttachmentDto) => {
		try {
			const actionResult = await dispatchWithNotifications({
				action: addAttachmentsAction,
				payload: formState,
				successMessage: "Document successfully uploaded.",
				errorMessage: "Could not upload document.",
			});
			if (addAttachmentsAction.fulfilled.match(actionResult)) {
				dispatch(attachDocumentsToLoadAction(actionResult.payload));
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleDeleteAttachment = async (attachmentId: number | string) => {
		try {
			const actionResult = await dispatchWithNotifications({
				action: deleteAttachmentAction,
				payload: attachmentId,
				successMessage: "Document successfully deleted.",
				errorMessage: "Could not delete document.",
			});
			if (deleteAttachmentAction.fulfilled.match(actionResult)) {
				dispatch(deleteDocumentFromLoadAction(attachmentId));
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleDownloadAttachment = (attachmentId: number | string) => {
		dispatch(fetchAttachmentUriAction(attachmentId));
	};

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchLoadTypesAction,
			errorMessage: "Could not fetch load types.",
		});
	}, [dispatchWithNotifications]);

	return (
		<>
			<CelerumCustomCard
				buttonName="Edit Info"
				buttonSvgIcon={editGridIconWhite}
				onClick={() => setShowModal({ ...showModal, update: true })}
				headerComponents={
					<div className={styles.viewDetailsButtonContainer}>
						<CelerumViewDetailsButton
							title={`View Documents (${load?.documents?.length ?? 0})`}
							onClick={() => setShowModal({ ...showModal, documents: true })}
							disabled={loading.load || !load || !load.documents}
						/>
					</div>
				}
				title="Load Information"
				className={className}
				disabled={loading.load || !load}
			>
				{loading.load ? (
					<div className={styles.verticalWrapper}>
						<CelerumLoader visible />
					</div>
				) : load ? (
					<LoadInformationCardContainer loadDetails={renderedLoadDetails} />
				) : (
					!loading.load && (
						<div className={styles.verticalWrapper}>
							<div className={styles.messageWrapper}>
								<span>There was a problem while loading load information.</span>
								<span>Please try again later.</span>
							</div>
						</div>
					)
				)}
			</CelerumCustomCard>
			{load && (
				<>
					<CelerumModal
						title="View Documents"
						width={ModalSize.Small}
						visible={showModal.documents}
						toggleDialog={closeModal}
					>
						<CelerumDocumentsForm
							documents={load.documents}
							entityId={load.id}
							entityUsage={AttachmentUsage.Load}
							onClose={closeModal}
							handleAddAttachments={handleAddAttachments}
							handleDeleteAttachment={handleDeleteAttachment}
							handleDownloadAttachment={handleDownloadAttachment}
						/>
					</CelerumModal>
					{renderedLoadDetails && (
						<CelerumModal
							title="Edit Load Information"
							width={modalSize}
							visible={showModal.update}
							toggleDialog={closeModal}
						>
							<EditLoadInformationForm
								formState={renderedLoadDetails}
								onClose={closeModal}
								setModalSize={setModalSize}
							/>
						</CelerumModal>
					)}
				</>
			)}
		</>
	);
};

import { Button, type ButtonProps } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import {
	Menu,
	type MenuItemModel,
	type MenuSelectEvent,
} from "@progress/kendo-react-layout";
import cls from "classnames";
import { useEffect, useRef, useState } from "react";
import {
	deleteGridIcon,
	documentsIcon,
	downArrowIcon,
	editGridIcon,
	eyeNormalIcon,
	greenEyeIcon,
	moreDetailsIcon,
} from "../../../../../models/src/lib/constants/icon.constants";
import { CelerumPopup } from "../celerum-popup/celerum-popup.component";
import styles from "./celerum-buttons.module.scss";

interface CelerumMoreDetailsButtonGridProps extends ButtonProps {
	items: MenuItemModel[];
	text?: string;
	handleOnSelect?: (e: MenuSelectEvent) => void;
}

interface CelerumButtonProps extends ButtonProps {
	title: string;
	disabled?: boolean;
	primary?: boolean;
	type?: "button" | "submit" | "reset";
}

export const CelerumSubmitButton = (props: CelerumButtonProps) => {
	return (
		<div className={styles.submitButtonContainer}>
			<CelerumButton {...props} />
		</div>
	);
};

export const CelerumButton = ({
	svgIcon,
	title,
	className,
	...extraProps
}: CelerumButtonProps) => {
	return svgIcon ? (
		<Button
			svgIcon={undefined}
			className={cls(styles.addGrid, className)}
			{...extraProps}
		>
			<SvgIcon icon={svgIcon} width={20} height={20} />
			<span className={styles.title}>{title}</span>
		</Button>
	) : (
		<Button className={cls(styles.addGrid, className)} {...extraProps}>
			{title}
		</Button>
	);
};

export const CelerumButtonEditGrid = (props: CelerumButtonProps) => {
	return (
		<Button className={styles.buttonMore} {...props}>
			<SvgIcon className={styles.editGridIcon} icon={editGridIcon} />
		</Button>
	);
};

export const CelerumButtonDeleteGrid = (props: CelerumButtonProps) => {
	return (
		<Button className={styles.buttonDeleteGrid} {...props}>
			<SvgIcon className={styles.deleteGridIcon} icon={deleteGridIcon} />
		</Button>
	);
};

export const CelerumViewButtonGrid = (props: CelerumButtonProps) => {
	return (
		<Button className={styles.buttonDeleteGrid} {...props}>
			<SvgIcon
				className={styles.deleteGreenIcon}
				icon={greenEyeIcon}
				width={26}
			/>
		</Button>
	);
};

export const CelerumViewDocumentsButtonGrid = (props: CelerumButtonProps) => {
	return (
		<Button className={styles.buttonDeleteGrid} {...props}>
			<SvgIcon
				className={styles.deleteGreenIcon}
				icon={documentsIcon}
				width={25}
			/>
		</Button>
	);
};

export const CelerumMoreDetailsButtonGrid = ({
	handleOnSelect,
	items,
	text,
}: CelerumMoreDetailsButtonGridProps) => {
	const anchor = useRef<HTMLButtonElement | null>(null);

	const [show, setShow] = useState(false);
	const onClick = () => {
		setShow(!show);
	};

	const handleValueSelected = (e: MenuSelectEvent) => {
		setShow(false);
		if (!handleOnSelect) {
			return;
		}
		handleOnSelect(e);
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				(event.target as HTMLElement)?.className !== "k-menu-link-text" &&
				anchor.current &&
				!anchor.current.contains(event.target as Node)
			) {
				setShow(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div style={{ position: "relative" }}>
			<button
				type="button"
				className={`k-button k-button-md k-button-solid k-button-solid-base k-rounded-md ${
					text ? styles.buttonAction : styles.buttonMore
				}`}
				onClick={onClick}
				ref={anchor}
			>
				<span className="k-button-text">
					{text ? (
						<span className={styles.buttonActionText}>
							{text}
							<SvgIcon
								className={styles.buttonActionText__downIcon}
								icon={downArrowIcon}
							/>
						</span>
					) : (
						<SvgIcon className={styles.deleteGridIcon} icon={moreDetailsIcon} />
					)}
				</span>
			</button>
			<CelerumPopup
				anchor={anchor}
				popupAlign={{ horizontal: "center", vertical: "top" }}
				show={show}
			>
				<Menu
					vertical={true}
					style={{ display: "inline-block", width: "220px" }}
					items={items}
					onSelect={handleValueSelected}
					className="popupMenu"
				/>
			</CelerumPopup>
		</div>
	);
};

export const CelerumConfirmationDeleteButton = (props: CelerumButtonProps) => {
	return (
		<Button className={styles.deleteConfirmationButton} {...props}>
			{props.title}
		</Button>
	);
};

export const CelerumConfirmationButton = (props: CelerumButtonProps) => {
	return (
		<Button
			{...props}
			className={cls(styles.confirmationButton, styles.addGrid)}
		>
			{props.title}
		</Button>
	);
};

export const CelerumViewDetailsButton = (props: CelerumButtonProps) => {
	return (
		<Button className={cls(styles.root, styles.viewButton)} {...props}>
			<span className={styles.viewButton__title}>
				<SvgIcon icon={eyeNormalIcon} width={20} height={20} />
				<span className={styles.viewButton__label}>{props.title}</span>
			</span>
		</Button>
	);
};

export const CelerumWhiteButton = (props: CelerumButtonProps) => {
	return (
		<Button className={styles.whiteButton} {...props}>
			{props.title}
		</Button>
	);
};

import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import {
	CUSTOMERS_FEATURE_KEY,
	NEW_CUSTOMER_STATE,
} from "../../../../common/models/src/lib/constants/customers.constants";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	CustomersState,
	ICustomer,
	ICustomerResponseDto,
} from "../../../../common/models/src/lib/interfaces/customer.interface";
import {
	createCustomer,
	deleteCustomer,
	fetchCustomers,
	syncWithSage,
	updateCustomer,
} from "./customers-data-access";

const initialState: CustomersState = {
	data: [],
	total: 0,
	aggregateResults: null,
	loading: false,
	errors: null,
	syncedWithSage: false,
};

export const fetchCustomersAction = createAsyncThunk(
	"customers/fetchCustomers",
	(params: CelerumQueryParams = {}) => {
		return fetchCustomers(params);
	},
);

export const createCustomerAction = createAsyncThunk(
	"customers/createCustomer",
	(customer: ICustomer) => {
		return createCustomer(customer);
	},
);

export const updateCustomerAction = createAsyncThunk(
	"customers/updateCustomer",
	(customer: ICustomer) => {
		return updateCustomer(customer);
	},
);

export const deleteCustomerAction = createAsyncThunk(
	"customers/deleteCustomer",
	(id: number) => {
		return deleteCustomer(id);
	},
);

export const syncWithSageAction = createAsyncThunk(
	"customers/syncWithSage",
	() => {
		return syncWithSage();
	},
);

const customersSlice = createSlice({
	name: CUSTOMERS_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		clearCustomersAction: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchCustomersAction.pending, (state: CustomersState) => {
				state.loading = true;
			})
			.addCase(createCustomerAction.pending, (state: CustomersState) => {
				state.loading = true;
			})
			.addCase(updateCustomerAction.pending, (state: CustomersState) => {
				state.loading = true;
			})
			.addCase(deleteCustomerAction.pending, (state: CustomersState) => {
				state.loading = true;
			})
			.addCase(syncWithSageAction.pending, (state: CustomersState) => {
				state.loading = true;
			})
			/** Fulfilled */
			.addCase(
				fetchCustomersAction.fulfilled,
				(state: CustomersState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults, page, pageSize } =
						action.payload;
					const customers = data as ICustomer[];

					/** Check if the data is already fetched
					 * If it is, just update the data
					 * If it is not, fill the array with NEW_CUSTOMER_STATE
					 */
					const newCustomers =
						state.data.length === total
							? [...state.data]
							: new Array(total).fill(NEW_CUSTOMER_STATE);

					/** Fill newCustomers with the fetched data */
					customers.forEach((customer, i) => {
						if (page && pageSize) {
							const index = (page - 1) * pageSize + i;
							newCustomers[index] = {
								...newCustomers[index],
								...customer,
							};
						}
					});

					state.total = total;
					state.data = newCustomers;
					state.syncedWithSage = newCustomers.some(
						(customer) => customer.isInSync,
					);
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			.addCase(
				createCustomerAction.fulfilled,
				(state: CustomersState, action: PayloadAction<ICustomer>) => {
					state.data = [action.payload as ICustomerResponseDto, ...state.data];
					state.loading = false;
					state.total += 1;
				},
			)
			.addCase(
				updateCustomerAction.fulfilled,
				(state: CustomersState, action: PayloadAction<ICustomer>) => {
					const index = state.data.findIndex(
						(customer) => customer.id === action.payload.id,
					);
					state.data[index] = action.payload as ICustomerResponseDto;
					state.loading = false;
				},
			)
			.addCase(
				deleteCustomerAction.fulfilled,
				(state: CustomersState, action: PayloadAction<number | null>) => {
					state.data = state.data.filter(
						(customer) => customer.id !== action.payload,
					);
					state.loading = false;
					state.total -= 1;
				},
			)
			.addCase(syncWithSageAction.fulfilled, (state: CustomersState) => {
				state.loading = false;
			})
			/** Rejected */
			.addCase(fetchCustomersAction.rejected, (state: CustomersState) => {
				state.loading = false;
			})
			.addCase(createCustomerAction.rejected, (state: CustomersState) => {
				state.loading = false;
			})
			.addCase(updateCustomerAction.rejected, (state: CustomersState) => {
				state.loading = false;
			})
			.addCase(deleteCustomerAction.rejected, (state: CustomersState) => {
				state.loading = false;
			})
			.addCase(syncWithSageAction.rejected, (state: CustomersState) => {
				state.loading = false;
			});
	},
});

/** Reducer */
export const customersReducer = customersSlice.reducer;

/** Actions */
export const { clearCustomersAction } = customersSlice.actions;

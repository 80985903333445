import { LegStatus } from "../enums/leg.enum";

export const LEGS_FEATURE_KEY = "legs";

const STORAGE_LEG = {
	id: 1,
	name: "Storage",
};

const FERRY_LEG = {
	id: 2,
	name: "Ferry",
};

const CLEAR_CUSTOMS_LEG = {
	id: 3,
	name: "Clear Customs",
};

export const COLLECTION_LEG = {
	id: 4,
	name: "Collection",
};

const SEGMENT_LEG = {
	id: 5,
	name: "Segment",
};

export const DELIVERY_LEG = {
	id: 6,
	name: "Delivery",
};

export const COLLECT_DELIVER_LEG = {
	id: 7,
	name: "Collect Deliver",
};

const BLANK_LEG = {
	id: 8,
	name: "Blank",
};

const CONTAINER_PICK_UP_LEG = {
	id: 9,
	name: "Container Pick Up",
};

const CONTAINER_DROP_OFF_LEG = {
	id: 10,
	name: "Container Drop Off",
};

const CONTAINER_GOODS_COLLECTION_LEG = {
	id: 11,
	name: "Container Goods Collection",
};

const CONTAINER_GOODS_DELIVERY_LEG = {
	id: 12,
	name: "Container Goods Delivery",
};

const LOAD_AND_LASH_LEG = {
	id: 13,
	name: "Load and Lash",
};

const DEVAN_LEG = {
	id: 14,
	name: "Devan",
};

export const LEG_TYPES = [
	STORAGE_LEG,
	FERRY_LEG,
	CLEAR_CUSTOMS_LEG,
	COLLECTION_LEG,
	SEGMENT_LEG,
	DELIVERY_LEG,
	COLLECT_DELIVER_LEG,
	BLANK_LEG,
	CONTAINER_PICK_UP_LEG,
	CONTAINER_DROP_OFF_LEG,
	CONTAINER_GOODS_COLLECTION_LEG,
	CONTAINER_GOODS_DELIVERY_LEG,
	LOAD_AND_LASH_LEG,
	DEVAN_LEG,
];

export const LEG_STATUSES = [
	{ id: LegStatus.New, name: "New" },
	{ id: LegStatus.Planned, name: "Planned" },
	{ id: LegStatus.Accepted, name: "Accepted" },
	{ id: LegStatus.InStartPosition, name: "In start position" },
	{ id: LegStatus.Underway, name: "Underway" },
	{ id: LegStatus.InEndPosition, name: "In end position" },
	{ id: LegStatus.SignedOff, name: "Signed off" },
	{ id: LegStatus.NotStarted, name: "Not started" },
	{ id: LegStatus.DocumentsReady, name: "Documents ready" },
	{ id: LegStatus.Clearing, name: "Clearing" },
	{ id: LegStatus.CustomsCleared, name: "Customs cleared" },
	{ id: LegStatus.WorkFinished, name: "Work finished" },
	{ id: LegStatus.BookingInProgress, name: "Booking in progress" },
	{ id: LegStatus.Booked, name: "Booked" },
	{ id: LegStatus.Paused, name: "Paused" },
	{ id: LegStatus.Cancelled, name: "Cancelled" },
	{ id: LegStatus.Archived, name: "Archived" },
	{ id: LegStatus.PartOfALoad, name: "Part of a load" },
	{ id: LegStatus.ReadyForWork, name: "Ready for work" },
];

export const LEGS_PAGE_SIZE = 50;

export const LEG_STATUS_MAPPINGS: {
	[key: number]: { title: string; className: string };
} = {
	1: { title: "New", className: "gray" },
	2: { title: "Planned", className: "yellow" },
	3: { title: "Accepted", className: "blue" },
	4: { title: "In start position", className: "violet" },
	5: { title: "Underway", className: "orange" },
	6: { title: "In end position", className: "pink" },
	7: { title: "Signed off", className: "green" },
	8: { title: "Not started", className: "gray" },
	9: { title: "Documents ready", className: "darkGreen" },
	10: { title: "Clearing", className: "blue" },
	11: { title: "Customs cleared", className: "green" },
	12: { title: "Work finished", className: "green" },
	13: { title: "Booking in progress", className: "orange" },
	14: { title: "Booked", className: "green" },
	15: { title: "Paused", className: "lightGray" },
	16: { title: "Cancelled", className: "red" },
	17: { title: "Archived", className: "gray" },
	18: { title: "Part of a load", className: "purple" },
	19: { title: "Ready for work", className: "purple" },
};

export const NEW_LEG_STATE = {
	id: -1,
	jobType: {},
	type: 0,
	status: 0,
	availableStatuses: [],
};

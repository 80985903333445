import { SvgIcon } from "@progress/kendo-react-common";
import { Card } from "@progress/kendo-react-layout";
import type { SVGIcon } from "@progress/kendo-svg-icons";
import { useNavigate } from "react-router-dom";
import { collectionIcon } from "../../../../../models/src/lib/constants/icon.constants";
import { NOT_AVAILABLE } from "../../../../../models/src/lib/constants/messages.constants";
import type { IBase } from "../../../../../models/src/lib/interfaces/base.interface";
import { CelerumButton } from "../celerum-buttons/celerum-buttons.component";
import styles from "./celerum-cards.module.scss";
import { AssociatedEntitiesWrapper } from "./components/associated-entities-wrapper/associated-entities-wrapper.component";

interface CelerumCardProps {
	className: string | undefined;
	children: React.ReactNode;
}

interface CelerumCustomCardProps {
	title: string;
	buttonSvgIcon?: SVGIcon;
	buttonName: string;
	className: string | undefined;
	children: React.ReactNode;
	disabled: boolean;
	headerComponents?: React.ReactNode;
	onClick: () => void;
}

interface CelerumAddressCardProps {
	title: string;
	name: string;
	address: string | null;
	date: string;
	icon?: SVGIcon;
}

interface CelerumDetailsCardProps {
	svgIcon: SVGIcon;
	label: string;
	value: number | string | IBase[];
	svgIconStyle?: string;
	type?: string;
	navigationUrl?: string;
}

export const CelerumCard = ({ className, children }: CelerumCardProps) => (
	<Card className={`${className} ${styles.card}`}>{children}</Card>
);

export const CelerumCustomCard = ({
	title,
	buttonName,
	children,
	disabled,
	headerComponents,
	className,
	buttonSvgIcon,
	onClick,
}: CelerumCustomCardProps) => (
	<CelerumCard className={className}>
		<div className={styles.customCard__header}>
			<h1 className={styles.customCard__header__title}>{title}</h1>
			<div className={styles.customCard__header__buttons}>
				{headerComponents}
				<CelerumButton
					onClick={onClick}
					title={buttonName}
					disabled={disabled}
					svgIcon={buttonSvgIcon}
				/>
			</div>
		</div>
		{children}
	</CelerumCard>
);

export const CelerumAddressCard = ({
	title,
	address,
	name,
	date,
	icon = collectionIcon,
}: CelerumAddressCardProps) => (
	<div className={styles.addressCard}>
		<div className={styles.addressCard__header}>
			<SvgIcon width={25} icon={icon} />
			<span className={styles.addressCard__header__title}>{title}</span>
		</div>
		<div className={styles.addressCard__content}>
			<span className={styles.addressCard__content__name}>
				{name || "No location name found."}
			</span>
			<span className={styles.addressCard__content__address}>
				{address || "No address found."}
			</span>
			<span className={styles.addressCard__content__date}>
				{date || "No date found."}
			</span>
		</div>
	</div>
);

export const CelerumDetailsCard = ({
	svgIcon,
	svgIconStyle,
	label,
	value,
	type,
	navigationUrl,
}: CelerumDetailsCardProps) => {
	const navigate = useNavigate();

	const navigateToUrl = (id: number) => {
		if (navigationUrl) {
			navigate(`${navigationUrl}/${id}`, {
				state: { from: window.location.pathname },
			});
		}
	};

	const renderValue = (value: string | IBase[] | number) => {
		switch (type) {
			case "array":
				return (
					<AssociatedEntitiesWrapper
						title={label}
						values={value as IBase[]}
						navigateToEntity={navigateToUrl}
					/>
				);
			default:
				return (
					<span className={styles.detailsCard__content__value}>
						{(value as string | number) ?? NOT_AVAILABLE}
					</span>
				);
		}
	};

	return (
		<div className={styles.detailsCard}>
			<div className={styles.detailsCard__header}>
				<SvgIcon className={svgIconStyle} width={18} icon={svgIcon} />
				<span className={styles.detailsCard__header__title}>{label}</span>
			</div>
			<div className={styles.detailsCard__content}>{renderValue(value)}</div>
		</div>
	);
};

import {
	Field,
	Form,
	FormElement,
	type FormProps,
} from "@progress/kendo-react-form";
import { useEffect, useMemo, useState } from "react";
import { USER_STATUSES } from "../../../../../../common/models/src/lib/constants/user.constants";
import { ErrorCode } from "../../../../../../common/models/src/lib/enums/error-codes.enum";
import { UserStatus } from "../../../../../../common/models/src/lib/enums/user.enum";
import type { IDriver } from "../../../../../../common/models/src/lib/interfaces/driver.interface";
import type { IUserRequestDto } from "../../../../../../common/models/src/lib/interfaces/user.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumFormDropDownList,
	CelerumFormInput,
} from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import {
	createUserAction,
	resetCreatedUserSuccess,
	resetUsersErrorCode,
	updateUserAction,
} from "../../../../../data-access/src/lib/users.slice";

interface UserFormProps {
	formState: IUserRequestDto | undefined;
	unlinkedDrivers: IDriver[];
	onClose: () => void;
}

export const UserForm = ({
	formState,
	unlinkedDrivers,
	onClose,
}: UserFormProps) => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const {
		users: { roles, errorCode, message, createdUserSuccess, loading },
	} = useAppSelector((state) => ({
		users: state.users,
	}));

	const [roleId, setRoleId] = useState<string | undefined>(
		formState?.roles?.[0]?.id,
	);
	const [roleName, setRoleName] = useState<string | undefined>(
		formState?.roles?.[0]?.name,
	);

	const renderedUnlinkedDrivers = useMemo(
		() =>
			formState?.driver
				? [...unlinkedDrivers, formState.driver]
				: unlinkedDrivers,
		[unlinkedDrivers, formState?.driver],
	);

	const handleCreateUser = async (user: IUserRequestDto) => {
		try {
			const actionResult = await dispatchWithNotifications({
				action: createUserAction,
				payload: user,
				successMessage: `User ${user.firstName} ${user.lastName} was successfully created.`,
				errorMessage: `Could not create user ${user.firstName} ${user.lastName}.`,
			});

			if (createUserAction.fulfilled.match(actionResult)) {
				dispatch(resetCreatedUserSuccess());
				dispatch(resetUsersErrorCode());
			}
		} catch (e) {
			console.error(e);
		}
	};

	const handleSubmit: FormProps["onSubmit"] = async (dataItem) => {
		const requestObject: IUserRequestDto = {
			id: dataItem.id,
			firstName: dataItem.firstName,
			lastName: dataItem.lastName,
			email: dataItem.email,
			status: dataItem?.active?.id || UserStatus.INACTIVE,
			roleId: roleId,
			driverId: dataItem.driver?.id,
		};

		if (formState) {
			const actionResult = await dispatchWithNotifications({
				action: updateUserAction,
				payload: requestObject,
				successMessage: `User ${requestObject.firstName} ${requestObject.lastName} was successfully updated.`,
				errorMessage: `Could not update user ${requestObject.firstName} ${requestObject.lastName}.`,
			});
			updateUserAction.fulfilled.match(actionResult) && onClose();
		} else {
			handleCreateUser(requestObject);
		}
	};

	useEffect(() => {
		createdUserSuccess && onClose();
	}, [createdUserSuccess, onClose]);

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formState}
			render={(formRenderProps) => (
				<FormElement>
					<fieldset className="k-form-fieldset">
						<Field
							id="firstName"
							name="firstName"
							label="First Name"
							component={CelerumFormInput}
							maxLength={250}
							validator={requiredValidator}
							required
							focused="true"
						/>
						<Field
							id="lastName"
							name="lastName"
							label="Last Name"
							component={CelerumFormInput}
							maxLength={250}
							validator={requiredValidator}
							required
						/>
						<Field
							id="email"
							name="email"
							disabled={!!formState}
							type="email"
							label="Email"
							component={CelerumFormInput}
							validator={requiredValidator}
							required
							errorMessage={
								errorCode === ErrorCode.INVALID_MODEL ? message : ""
							}
						/>
						<Field
							id="roles[0]"
							name="roles[0]"
							label="Role"
							dataItemKey="id"
							textField="name"
							data={roles.filter((role) => role.name !== "SuperAdmin")}
							onChange={(e) => {
								setRoleId(e.target.value.id);
								setRoleName(e.target.value.name);
							}}
							component={CelerumFormDropDownList}
							validator={requiredValidator}
							required
						/>
						{roleName === "Driver" && (
							<Field
								id="driver"
								dataItemKey="id"
								label="Driver"
								textField="fullName"
								name="driver"
								component={CelerumFormDropDownList}
								data={renderedUnlinkedDrivers}
								validator={requiredValidator}
								required
							/>
						)}
						{formState && (
							<Field
								id="active"
								name="active"
								label="Status"
								dataItemKey="id"
								textField="name"
								data={USER_STATUSES}
								component={CelerumFormDropDownList}
								validator={requiredValidator}
								required
							/>
						)}
					</fieldset>
					<CelerumSubmitButton
						type="submit"
						disabled={!formRenderProps.allowSubmit || loading}
						title="Submit"
					/>
				</FormElement>
			)}
		/>
	);
};

import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import {
	NEW_SUBCONTRACTOR_STATE,
	SUBCONTRACTORS_FEATURE_KEY,
} from "../../../../common/models/src/lib/constants/subcontractor.constants";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	ISubcontractorRequestDto,
	ISubcontractorResponseDto,
	SubcontractorState,
} from "../../../../common/models/src/lib/interfaces/subcontractor.interface";
import {
	createSubcontractor,
	deleteSubcontractor,
	fetchSubcontractors,
	fetchSubcontractorsAll,
	updateSubcontractor,
} from "./subcontractors-data-access";

const initialState: SubcontractorState = {
	data: [],
	total: 0,
	aggregateResults: null,
	loading: false,
	errors: null,
};

export const fetchSubcontractorsAllAction = createAsyncThunk(
	"subcontractors/fetchSubcontractorsAll",
	() => fetchSubcontractorsAll(),
);

export const fetchSubcontractorsAction = createAsyncThunk(
	"subcontractors/fetchSubcontractors",
	(params: CelerumQueryParams) => {
		return fetchSubcontractors(params);
	},
);

export const createSubcontractorAction = createAsyncThunk(
	"subcontractors/createSubcontractor",
	(subcontractor: ISubcontractorRequestDto) => {
		return createSubcontractor(subcontractor);
	},
);

export const updateSubcontractorAction = createAsyncThunk(
	"subcontractors/updateSubcontractor",
	(subcontractor: ISubcontractorRequestDto) => {
		return updateSubcontractor(subcontractor);
	},
);

export const deleteSubcontractorAction = createAsyncThunk(
	"subcontractors/deleteSubcontractor",
	(id: number) => {
		return deleteSubcontractor(id);
	},
);

const subcontractorsSlice = createSlice({
	name: SUBCONTRACTORS_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		clearSubcontractorsAction: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(
				fetchSubcontractorsAction.pending,
				(state: SubcontractorState) => {
					state.loading = true;
				},
			)
			.addCase(
				createSubcontractorAction.pending,
				(state: SubcontractorState) => {
					state.loading = true;
				},
			)
			.addCase(
				updateSubcontractorAction.pending,
				(state: SubcontractorState) => {
					state.loading = true;
				},
			)
			.addCase(
				deleteSubcontractorAction.pending,
				(state: SubcontractorState) => {
					state.loading = true;
				},
			)
			.addCase(
				fetchSubcontractorsAllAction.pending,
				(state: SubcontractorState) => {
					state.loading = true;
				},
			)
			/** Fulfilled */
			.addCase(
				fetchSubcontractorsAction.fulfilled,
				(state: SubcontractorState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults, page, pageSize } =
						action.payload;
					const subcontractors = data as ISubcontractorResponseDto[];

					/** Check if the data is already fetched
					 * If it is, just update the data
					 * If it is not, fill the array with NEW_SUBCONTRACTOR_STATE
					 */
					const newSubcontractors =
						state.data.length === total
							? [...state.data]
							: new Array(total).fill(NEW_SUBCONTRACTOR_STATE);

					/** Fill newSubcontractors with the fetched data */
					subcontractors.forEach((subcontractor, i) => {
						if (page && pageSize) {
							const index = (page - 1) * pageSize + i;
							newSubcontractors[index] = {
								...newSubcontractors[index],
								...subcontractor,
							};
						}
					});

					state.total = total;
					state.data = newSubcontractors;
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			.addCase(
				createSubcontractorAction.fulfilled,
				(
					state: SubcontractorState,
					action: PayloadAction<ISubcontractorResponseDto>,
				) => {
					const response = {
						...action.payload,
						truckTypes: action.payload.truckTypes.map((truckType) => ({
							...truckType,
							vehicleType: "TRUCK",
						})),
						trailerTypes: action.payload.trailerTypes.map((trailerType) => ({
							...trailerType,
							vehicleType: "TRAILER",
						})),
					};
					state.data = [response, ...state.data];
					state.loading = false;
					state.total += 1;
				},
			)
			.addCase(
				updateSubcontractorAction.fulfilled,
				(
					state: SubcontractorState,
					action: PayloadAction<ISubcontractorResponseDto>,
				) => {
					const index = state.data.findIndex(
						(subcontractor) => subcontractor.id === action.payload.id,
					);
					const response = {
						...action.payload,
						truckTypes: action.payload.truckTypes.map((truckType) => ({
							...truckType,
							vehicleType: "TRUCK",
						})),
						trailerTypes: action.payload.trailerTypes.map((trailerType) => ({
							...trailerType,
							vehicleType: "TRAILER",
						})),
					};
					state.data[index] = response;
					state.loading = false;
				},
			)
			.addCase(
				deleteSubcontractorAction.fulfilled,
				(state: SubcontractorState, action: PayloadAction<number | null>) => {
					state.data = state.data.filter(
						(subcontractor) => subcontractor.id !== action.payload,
					);
					state.loading = false;
					state.total -= 1;
				},
			)
			.addCase(
				fetchSubcontractorsAllAction.fulfilled,
				(state: SubcontractorState, action: PayloadAction<CelerumResponse>) => {
					const { data, total } = action.payload;
					const subcontractors = data as ISubcontractorResponseDto[];

					state.data = subcontractors;
					state.total = total;
					state.loading = false;
				},
			)
			/** Rejected */
			.addCase(
				fetchSubcontractorsAction.rejected,
				(state: SubcontractorState) => {
					state.loading = false;
				},
			)
			.addCase(
				createSubcontractorAction.rejected,
				(state: SubcontractorState) => {
					state.loading = false;
				},
			)
			.addCase(
				updateSubcontractorAction.rejected,
				(state: SubcontractorState) => {
					state.loading = false;
				},
			)
			.addCase(
				deleteSubcontractorAction.rejected,
				(state: SubcontractorState) => {
					state.loading = false;
				},
			)
			.addCase(
				fetchSubcontractorsAllAction.rejected,
				(state: SubcontractorState) => {
					state.loading = false;
				},
			);
	},
});

/** Reducer */
export const subcontractorsReducer = subcontractorsSlice.reducer;

/** Actions */
export const { clearSubcontractorsAction } = subcontractorsSlice.actions;

import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, number, object, string } from "yup";
import { DFlex } from "../display/DFlex";
import { GenericForm, IText } from "../forms/GenericForm";
import { type TypedGridColumnProps, jobApi, toasted } from "../helpers";
import { GenericPage } from "./GenericPage";

const TruckTypeSchema = object({
	id: number().label("ID"),
	name: string().required().label("Name"),
});
type TruckTypeFormObject = InferType<typeof TruckTypeSchema>;

type TruckTypeFormProps = {
	defaultValues?: Partial<TruckTypeFormObject>;
	onSubmit: (data: TruckTypeFormObject) => void;
};
const TruckTypeForm = ({ defaultValues, onSubmit }: TruckTypeFormProps) => {
	const form = useForm<TruckTypeFormObject>({
		resolver: yupResolver(TruckTypeSchema),
		defaultValues,
	});
	return (
		<GenericForm
			form={form}
			schema={TruckTypeSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<IText n="name" />
				</div>
			</DFlex>
		</GenericForm>
	);
};
const TruckTypeFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<TruckTypeFormProps, "onSubmit" | "defaultValues">) => {
	return (
		<TruckTypeForm
			defaultValues={defaultValues}
			onSubmit={async (data) => {
				const { id, ...rest } = data;
				const processData = async () => {
					if (id) {
						await jobApi.truckType.truckTypeUpdate({
							id,
							...rest,
							isArticulated: false,
						});
					} else {
						await jobApi.truckType.truckTypeCreate({
							...rest,
							isArticulated: false,
						});
					}
					onSubmit(data);
				};
				toasted(
					processData(),
					id ? "Updating Truck Type" : "Creating Truck Type",
				);
			}}
		/>
	);
};

type TruckType = TruckTypeFormObject & { id: number };
const defaultColumns: TypedGridColumnProps<TruckType>[] = [
	{ field: "name", title: "Name" },
];
const useFetchData = (): ComponentProps<
	typeof GenericPage<TruckType>
>["data"] => {
	const _truckTypes = useQuery({
		queryKey: ["jobApi.truckType.truckTypeList"],
		queryFn: () => jobApi.truckType.truckTypeList({}).then((x) => x.data.data),
		initialData: [],
	});
	const truckTypes = useMemo(
		() =>
			_truckTypes.data.map((x): TruckType => {
				return {
					id: x.id,
					name: x.name,
				};
			}),
		[_truckTypes.data],
	);
	return {
		data: truckTypes,
		retry: _truckTypes.refetch,
		loading: _truckTypes.isFetching,
	};
};
export const TruckTypesPage2 = () => {
	const data = useFetchData();
	const handleDelete = (id: number) =>
		toasted(
			jobApi.truckType.truckTypeDelete(id).then(data.retry),
			"Deleting Truck Type",
		);
	const getForm = (
		id: number | undefined,
		onSubmit: (data: TruckTypeFormObject) => void,
	) => {
		let defaultValues: Partial<TruckTypeFormObject> = {};
		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};
		return (
			<TruckTypeFormWithDTO onSubmit={onSubmit} defaultValues={defaultValues} />
		);
	};
	return (
		<GenericPage
			pageTitle="Truck Types"
			name="Truck Type"
			data={data}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
		/>
	);
};

import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, array, number, object, string } from "yup";
import { DFlex } from "../display/DFlex";
import {
	GenericForm,
	IMultiCreate,
	IMultiSelect,
	IText,
	ITextArea,
	type LoadOptionsFn,
} from "../forms/GenericForm";
import {
	type TypedGridColumnProps,
	jobApi,
	toasted,
	useLookupConstraints,
	useLookupTrailerTypes,
	useLookupTruckTypes,
} from "../helpers";
import { GenericPage } from "./GenericPage";

const SubcontractorSchema = object({
	id: number().label("ID"),
	name: string().required().label("Name"),
	emails: array()
		.of(string().email().required().label("Email"))
		.label("Emails"),
	phones: array().of(string().required().label("Phone")).label("Phones"),
	notes: string().label("Notes").max(50),
	trailerTypeIds: array()
		.of(number().required().label("Trailer Type"))
		.label("Trailer Types"),
	truckTypeIds: array()
		.of(number().required().label("Truck Type"))
		.label("Truck Types"),
	truckRegistrationNumbers: array()
		.of(string().required().label("Truck Registration Number"))
		.label("Truck Registration Numbers"),
	constraintIds: array()
		.of(number().required().label("Constraint"))
		.label("Constraints"),
});
type SubcontractorFormObject = InferType<typeof SubcontractorSchema>;

type SubcontractorFormProps = {
	defaultValues?: Partial<SubcontractorFormObject>;
	onSubmit: (data: SubcontractorFormObject) => void;
	lTrailerTypes: LoadOptionsFn;
	lTruckTypes: LoadOptionsFn;
	lConstraints: LoadOptionsFn;
};
const SubcontractorForm = ({
	defaultValues,
	onSubmit,
	lConstraints,
	lTrailerTypes,
	lTruckTypes,
}: SubcontractorFormProps) => {
	const form = useForm<SubcontractorFormObject>({
		resolver: yupResolver(SubcontractorSchema),
		defaultValues,
	});
	return (
		<GenericForm
			form={form}
			schema={SubcontractorSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<IText n="name" />
					<IMultiCreate n="emails" />
					<IMultiCreate n="phones" />
					<ITextArea n="notes" />
				</div>
				<div>
					<IMultiSelect n="trailerTypeIds" l={lTrailerTypes} />
					<IMultiSelect n="truckTypeIds" l={lTruckTypes} />
					<IMultiCreate n="truckRegistrationNumbers" />
					<IMultiSelect n="constraintIds" l={lConstraints} />
				</div>
			</DFlex>
		</GenericForm>
	);
};
const SubcontractorFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<SubcontractorFormProps, "onSubmit" | "defaultValues">) => {
	return (
		<SubcontractorForm
			defaultValues={defaultValues}
			lConstraints={useLookupConstraints()}
			lTrailerTypes={useLookupTrailerTypes()}
			lTruckTypes={useLookupTruckTypes()}
			onSubmit={async (data) => {
				const { id, ...rest } = data;
				const processData = async () => {
					if (id) {
						await jobApi.subcontractor.subcontractorUpdate({ id, ...rest });
					} else await jobApi.subcontractor.subcontractorCreate({ ...rest });
					onSubmit(data);
				};
				toasted(
					processData(),
					id ? "Updating Subcontractor" : "Creating Subcontractor",
				);
			}}
		/>
	);
};

type Subcontractor = SubcontractorFormObject & {
	id: number;
	constraintsString: string;
	trailerTypesString: string;
	truckTypesString: string;
};
const defaultColumns: TypedGridColumnProps<Subcontractor>[] = [
	{ field: "name", title: "Name" },
	{ field: "notes", title: "Notes" },
	{ field: "constraintsString", title: "Constraints" },
	{ field: "emails", title: "Emails" },
	{ field: "phones", title: "Phones" },
	{ field: "trailerTypesString", title: "Trailer Types" },
	{ field: "truckTypesString", title: "Truck Types" },
];
const useFetchData = (): ComponentProps<
	typeof GenericPage<Subcontractor>
>["data"] => {
	const _subcontractors = useQuery({
		queryKey: ["jobApi.subcontractor.subcontractorList"],
		queryFn: () =>
			jobApi.subcontractor.subcontractorList({}).then((x) => x.data.data),
		initialData: [],
	});
	const subcontractors = useMemo(
		() =>
			_subcontractors.data.map((x): Subcontractor => {
				return {
					id: x.id,
					name: x.name,
					notes: x.notes ?? undefined,
					emails: x.emails,
					phones: x.phones,
					trailerTypeIds: x.trailerTypes.map((x) => x.id),
					truckTypeIds: x.truckTypes.map((x) => x.id),
					constraintIds: x.constraints.map((x) => x.id),
					trailerTypesString: x.trailerTypes.map((x) => x.name).join(", "),
					truckTypesString: x.truckTypes.map((x) => x.name).join(", "),
					constraintsString: x.constraints.map((x) => x.name).join(", "),
				};
			}),
		[_subcontractors.data],
	);
	return {
		data: subcontractors,
		retry: _subcontractors.refetch,
		loading: _subcontractors.isFetching,
	};
};
export const SubcontractorsPage2 = () => {
	const data = useFetchData();
	const handleDelete = (id: number) =>
		toasted(
			jobApi.subcontractor.subcontractorDelete(id).then(data.retry),
			"Deleting Subcontractor",
		);
	const getForm = (
		id: number | undefined,
		onSubmit: (data: SubcontractorFormObject) => void,
	) => {
		let defaultValues: Partial<SubcontractorFormObject> = {};
		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};
		return (
			<SubcontractorFormWithDTO
				onSubmit={onSubmit}
				defaultValues={defaultValues}
			/>
		);
	};
	return (
		<GenericPage
			pageTitle="Subcontractors"
			name="Subcontractor"
			data={data}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
		/>
	);
};

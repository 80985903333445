import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { type ComponentProps, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, array, date, number, object, string } from "yup";
import { useDocumentsFormDialog } from "../dialogs/useDocumentsFormDialog";
import { DFlex } from "../display/DFlex";
import {
	GenericForm,
	IDate,
	IDateTime,
	IMultiSelect,
	ISelect,
	IText,
	ITextArea,
	type LoadOptionsFn,
} from "../forms/GenericForm";
import {
	AttachmentUsageType,
	type TypedGridColumnProps,
	jobApi,
	toDateString,
	toDatetimeString,
	toasted,
	useLookupConstraints,
	useLookupSubcontractors,
	useLookupTrailers,
	useLookupTruckTypes,
} from "../helpers";
import { toCell } from "../helpersReact";
import { GenericPage } from "./GenericPage";

const TruckSchema = object({
	id: number().label("ID"),
	name: string().required().label("Name"),
	nextInspectionTime: date().required().label("Next Inspection Date"),
	nextMOTDate: date().required().label("Next MOT Date"),
	notes: string().label("Notes"),
	registration: string().required().label("Registration"),
	subcontractorId: number().nullable().label("Subcontractor"),
	truckTypeId: number().required().label("Truck Type"),
	constraintIds: array()
		.of(number().required().label("Constraint"))
		.label("Constraints"),
	trailerId: number().label("Trailer"),
});

type TruckFormObject = InferType<typeof TruckSchema>;

type TruckFormProps = {
	defaultValues?: Partial<TruckFormObject>;
	onSubmit: (data: TruckFormObject) => void;
	lookupSubcontractors: LoadOptionsFn;
	lookupTruckTypes: LoadOptionsFn;
	lookupConstraints: LoadOptionsFn;
	lookupTrailers: LoadOptionsFn;
};

const TruckForm = ({
	defaultValues,
	onSubmit,
	lookupSubcontractors,
	lookupTruckTypes,
	lookupConstraints,
	lookupTrailers,
}: TruckFormProps) => {
	const form = useForm<TruckFormObject>({
		resolver: yupResolver(TruckSchema),
		defaultValues,
	});
	return (
		<GenericForm
			form={form}
			schema={TruckSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<IText n="name" />
					<IDateTime n="nextInspectionTime" />
					<ITextArea n="notes" />
				</div>
				<div>
					<IDate n="nextMOTDate" />
					<IText n="registration" />
					<ISelect n="subcontractorId" l={lookupSubcontractors} />
					<ISelect n="truckTypeId" l={lookupTruckTypes} />
					<IMultiSelect n="constraintIds" l={lookupConstraints} />
					<ISelect n="trailerId" l={lookupTrailers} />
				</div>
			</DFlex>
		</GenericForm>
	);
};

const TruckFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<TruckFormProps, "onSubmit" | "defaultValues">) => (
	<TruckForm
		defaultValues={defaultValues}
		lookupSubcontractors={useLookupSubcontractors()}
		lookupTruckTypes={useLookupTruckTypes()}
		lookupConstraints={useLookupConstraints()}
		lookupTrailers={useLookupTrailers()}
		onSubmit={async (data) => {
			const { id, ...rest } = data;
			const nextInspectionTime = rest.nextInspectionTime.toISOString();
			const nextMOTDate = rest.nextMOTDate.toISOString();
			const processData = async () => {
				if (id) {
					await jobApi.truck.truckUpdate({
						id,
						...rest,
						nextInspectionTime,
						nextMOTDate,
					});
				} else {
					await jobApi.truck.truckCreate({
						...rest,
						nextInspectionTime,
						nextMOTDate,
					});
				}
				onSubmit(data);
			};
			toasted(processData(), id ? "Updating Truck" : "Creating Truck");
		}}
	/>
);

type Truck = TruckFormObject & {
	id: number;
	nextInspectionTimeString: string;
	nextMOTDateString: string;
	subcontractorString: string;
	truckTypeString: string;
	constraintsString: string;
	trailerString: string;
};

const defaultColumns: TypedGridColumnProps<Truck>[] = [
	{ field: "name", title: "Name" },
	{
		field: "nextInspectionTime",
		title: "Next Inspection Date",
		cell: ({ dataItem }) => toCell(dataItem.nextInspectionTimeString),
	},
	{
		field: "nextMOTDate",
		title: "Next MOT Date",
		cell: ({ dataItem }) => toCell(dataItem.nextMOTDateString),
	},
	{ field: "notes", title: "Notes" },
	{ field: "registration", title: "Registration" },
	{ field: "subcontractorString", title: "Subcontractor" },
	{ field: "truckTypeString", title: "Truck Type" },
	{ field: "constraintsString", title: "Constraints" },
	{ field: "trailerString", title: "Trailer" },
];

const useFetchData = (): ComponentProps<typeof GenericPage<Truck>>["data"] => {
	const _trucks = useQuery({
		queryKey: ["jobApi.truck.truckList"],
		queryFn: () => jobApi.truck.truckList({}).then((x) => x.data.data),
		initialData: [],
	});

	const trucks = useMemo(
		() =>
			_trucks.data.map((x): Truck => {
				const nextInspectionTime = dayjs(x.nextInspectionTime).toDate();
				const nextMOTDate = dayjs(x.nextMOTDate ?? "").toDate();
				return {
					id: x.id,
					name: x.name,
					registration: x.registration,
					subcontractorId: x.subcontractor?.id,
					subcontractorString: x.subcontractor?.name ?? "",
					truckTypeId: x.truckType.id,
					truckTypeString: x.truckType?.name ?? "",
					nextInspectionTime: nextInspectionTime,
					nextInspectionTimeString: toDatetimeString(nextInspectionTime),
					nextMOTDate: nextMOTDate,
					nextMOTDateString: toDateString(nextMOTDate),
					notes: x.notes ?? undefined,
					constraintsString: x.constraints.map((x) => x.name).join(", "),
					constraintIds: x.constraints.map((x) => x.id),
					trailerId: x.trailer?.id,
					trailerString: x.trailer?.name ?? "",
				};
			}),
		[_trucks.data],
	);

	return { data: trucks, retry: _trucks.refetch, loading: _trucks.isFetching };
};

export const TrucksPage2 = () => {
	const data = useFetchData();
	const [showDocumentsFor, documentsForm] = useDocumentsFormDialog();

	const handleDelete = (id: number) =>
		toasted(jobApi.truck.truckDelete(id).then(data.retry), "Deleting Truck");

	const getForm = useCallback(
		(id: number | undefined, onSubmit: (data: TruckFormObject) => void) => {
			let defaultValues: Partial<TruckFormObject> = {
				nextInspectionTime: dayjs().startOf("d").add(1, "y").toDate(),
				nextMOTDate: dayjs().startOf("d").add(1, "y").toDate(),
			};

			if (id) {
				defaultValues = data.data.find((x) => x.id === id) ?? {};
			}

			return (
				<TruckFormWithDTO onSubmit={onSubmit} defaultValues={defaultValues} />
			);
		},
		[data.data],
	);

	const extraActions = useMemo(
		() => [
			{
				name: "View Documents",
				onClick: (x: Truck) => {
					showDocumentsFor({
						entityId: x.id,
						usage: AttachmentUsageType.Truck,
						onChange: data.retry,
					});
				},
			},
		],
		[showDocumentsFor, data.retry],
	);

	return (
		<>
			{documentsForm}
			<GenericPage
				pageTitle="Trucks"
				name="Truck"
				data={data}
				onDelete={handleDelete}
				defaultColumns={defaultColumns}
				getForm={getForm}
				extraActions={extraActions}
			/>
		</>
	);
};

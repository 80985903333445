import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, number, object, string } from "yup";
import { DFlex } from "../display/DFlex";
import {
	GenericForm,
	INumber,
	ISelect,
	type LoadOptionsFn,
} from "../forms/GenericForm";
import {
	type TypedGridColumnProps,
	jobApi,
	toasted,
	useLookupCurrencies,
} from "../helpers";
import { GenericPage } from "./GenericPage";

const ExchangeRateSchema = object({
	id: number().label("ID"),
	name: string().required().label("Name"),
	exchangeRate: number().required().positive().label("Exchange Rate"),
	fromCurrencyId: number()
		.required()
		.label("From Currency")
		.test(
			"not-equal",
			"The currencies must be different",
			(value, ctx) => value !== ctx.parent.toCurrencyId,
		),
	toCurrencyId: number().required().label("To Currency"),
});
type ExchangeRateFormObject = InferType<typeof ExchangeRateSchema>;

type ExchangeRateFormProps = {
	defaultValues?: Partial<ExchangeRateFormObject>;
	onSubmit: (data: ExchangeRateFormObject) => void;
	lCurrencies: LoadOptionsFn;
};
const ExchangeRateForm = ({
	defaultValues,
	onSubmit,
	lCurrencies,
}: ExchangeRateFormProps) => {
	const form = useForm<ExchangeRateFormObject>({
		resolver: yupResolver(ExchangeRateSchema),
		defaultValues,
	});
	return (
		<GenericForm
			form={form}
			schema={ExchangeRateSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<ISelect n="fromCurrencyId" l={lCurrencies} />
					<ISelect n="toCurrencyId" l={lCurrencies} />
					<INumber n="exchangeRate" step={0.01} />
				</div>
			</DFlex>
		</GenericForm>
	);
};
const ExchangeRateFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<ExchangeRateFormProps, "onSubmit" | "defaultValues">) => (
	<ExchangeRateForm
		defaultValues={defaultValues}
		lCurrencies={useLookupCurrencies()}
		onSubmit={async (data) => {
			const { id, ...rest } = data;
			const processData = async () => {
				if (id) {
					await jobApi.currencyExchangeRate.currencyExchangeRateUpdate({
						id,
						...rest,
					});
				} else {
					await jobApi.currencyExchangeRate.currencyExchangeRateCreate({
						...rest,
					});
				}
				onSubmit(data);
			};
			await toasted(
				processData(),
				id ? "Updating Exchange Rate" : "Creating Exchange Rate",
			);
		}}
	/>
);

type ExchangeRate = ExchangeRateFormObject & {
	id: number;
	fromCurrencyString: string;
	toCurrencyString: string;
};
const defaultColumns: TypedGridColumnProps<ExchangeRate>[] = [
	{ field: "name", title: "Name" },
	{ field: "exchangeRate", title: "Exchange Rate" },
];
const useFetchData = (): ComponentProps<
	typeof GenericPage<ExchangeRate>
>["data"] => {
	const _exchangeRates = useQuery({
		queryKey: ["jobApi.currencyExchangeRate.currencyExchangeRateList"],
		queryFn: () =>
			jobApi.currencyExchangeRate
				.currencyExchangeRateList({})
				.then((x) => x.data.data),
		initialData: [],
	});
	const exchangeRates = useMemo(
		() =>
			_exchangeRates.data.map((x): ExchangeRate => {
				return {
					id: x.id,
					name: `${x.fromCurrency.code} → ${x.toCurrency.code}`,
					exchangeRate: x.exchangeRate,
					fromCurrencyId: x.fromCurrency.id,
					fromCurrencyString: x.fromCurrency.code,
					toCurrencyId: x.toCurrency.id,
					toCurrencyString: x.toCurrency.code,
				};
			}),
		[_exchangeRates.data],
	);
	return {
		data: exchangeRates,
		retry: _exchangeRates.refetch,
		loading: _exchangeRates.isFetching,
	};
};
export const ExchangeRatesPage2 = () => {
	const data = useFetchData();
	const handleDelete = (id: number) =>
		toasted(
			jobApi.currencyExchangeRate
				.currencyExchangeRateDelete(id)
				.then(data.retry),
			"Deleting Exchange Rate",
		);
	const getForm = (
		id: number | undefined,
		onSubmit: (data: ExchangeRateFormObject) => void,
	) => {
		let defaultValues: Partial<ExchangeRateFormObject> = {};
		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};
		defaultValues.name = "_";
		return (
			<ExchangeRateFormWithDTO
				onSubmit={onSubmit}
				defaultValues={defaultValues}
			/>
		);
	};
	return (
		<GenericPage
			pageTitle="Exchange Rates"
			name="Exchange Rate"
			data={data}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
		/>
	);
};

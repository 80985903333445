import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import { ATTACHMENTS_FEATURE_KEY } from "../../../../common/models/src/lib/constants/attachment.constants";
import { NotificationType } from "../../../../common/models/src/lib/enums/notification-type.enum";
import type {
	AttachmentsState,
	IEntityAttachmentDto,
} from "../../../../common/models/src/lib/interfaces/attachment.interface";
import {
	addAttachments,
	deleteAttachment,
	getAttachmentUri,
} from "./attachments-data-access";

const initialState: AttachmentsState = {
	currentAttachmentUrlToDownload: undefined,
	loading: false,
	errors: null,
	notificationType: null,
	message: "",
};

export const addAttachmentsAction = createAsyncThunk(
	"attachments/addAttachments",
	(item: IEntityAttachmentDto) => {
		return addAttachments(
			item.documents,
			item.entityId,
			item.entity,
			item.isPod,
		);
	},
);

export const deleteAttachmentAction = createAsyncThunk(
	"attachments/deleteAttachment",
	(attachmentId: number | string) => {
		return deleteAttachment(attachmentId);
	},
);

export const fetchAttachmentUriAction = createAsyncThunk(
	"attachments/fetchAttachmentUri",
	(attachmentId: number | string) => {
		return getAttachmentUri(attachmentId);
	},
);

const attachmentsSlice = createSlice({
	name: ATTACHMENTS_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		clearAttachmentsAction: () => initialState,
		resetAttachmentToDownload: (state: AttachmentsState) => {
			state.currentAttachmentUrlToDownload = undefined;
		},
		resetAttachmentsNotificationAction: (state: AttachmentsState) => {
			state.notificationType = initialState.notificationType;
			state.message = initialState.message;
		},
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(addAttachmentsAction.pending, (state: AttachmentsState) => {
				state.loading = true;
			})
			.addCase(fetchAttachmentUriAction.pending, (state: AttachmentsState) => {
				state.loading = true;
			})
			.addCase(deleteAttachmentAction.pending, (state: AttachmentsState) => {
				state.loading = true;
			})
			/** Fulfilled */
			.addCase(addAttachmentsAction.fulfilled, (state: AttachmentsState) => {
				state.loading = false;
			})
			.addCase(deleteAttachmentAction.fulfilled, (state: AttachmentsState) => {
				state.loading = false;
			})
			.addCase(
				fetchAttachmentUriAction.fulfilled,
				(state: AttachmentsState, action: PayloadAction<string>) => {
					state.loading = false;
					state.currentAttachmentUrlToDownload = action.payload;
				},
			)
			/** Rejected */
			.addCase(addAttachmentsAction.rejected, (state: AttachmentsState) => {
				state.loading = false;
				state.notificationType = NotificationType.Error;
				state.message = "Could not upload the documents!";
			})
			.addCase(deleteAttachmentAction.rejected, (state: AttachmentsState) => {
				state.loading = false;
				state.notificationType = NotificationType.Error;
				state.message = "Could not delete the documents!";
			})
			.addCase(fetchAttachmentUriAction.rejected, (state: AttachmentsState) => {
				state.loading = false;
				state.notificationType = NotificationType.Error;
				state.message = "Could not download the document!";
			});
	},
});

/** Reducer */
export const attachmentsReducer = attachmentsSlice.reducer;

/** Actions */
export const { resetAttachmentToDownload } = attachmentsSlice.actions;

import { NOT_AVAILABLE } from "../../../../../../common/models/src/lib/constants/messages.constants";
import type { IGoodsResponseDto } from "../../../../../../common/models/src/lib/interfaces/goods.interface";
import type { TagItem } from "../../../../../../common/models/src/lib/types/tag.type";
import { useAppSelector } from "../../../../../../common/stores/src/lib/utils";
import { CelerumLoader } from "../../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { CelerumTag } from "../../../../../../common/ui/src/lib/components/celerum-tag/celerum-tag.component";
import {
	getFormattedCurrency,
	getFormattedValue,
} from "../../../../../../common/utils/src/lib/helpers/currency.helpers";
import { formatDate } from "../../../../../../common/utils/src/lib/helpers/date.helpers";
import styles from "./goods-container.module.scss";

export const GoodsContainer = () => {
	const { loading, goods } = useAppSelector((state) => ({
		goods: state.goods.data,
		loading: state.goods.loading,
	}));

	const renderItemContent = (
		label: string,
		value: string | number | TagItem[],
		showHorizontalLine = true,
	) => {
		return (
			<>
				<div className={styles.item__content}>
					<span className={styles.item__content__label}>{label}</span>
					{typeof value === "object" ? (
						<div className={styles.item__content__tags}>
							{value.map((item: TagItem) => (
								<CelerumTag name={item.name} key={item.id} />
							))}
						</div>
					) : (
						<span className={styles.item__content__value}>{value}</span>
					)}
				</div>
				{showHorizontalLine && <div className={styles.item__content__break} />}
			</>
		);
	};

	const renderItem = (item: IGoodsResponseDto) => {
		return (
			<div className={styles.item} key={item.id}>
				<div className={styles.item__header}>
					<span className={styles.item__header__title}>{item.name}</span>
				</div>
				{renderItemContent(
					"Collection Location",
					item?.collectionLocation?.name ?? NOT_AVAILABLE,
				)}
				{renderItemContent(
					"Collection Address",
					item?.collectionLocation?.address ?? NOT_AVAILABLE,
				)}
				{renderItemContent(
					"Destination Location",
					item?.deliveryLocation?.name ?? NOT_AVAILABLE,
				)}
				{renderItemContent(
					"Destination Address",
					item?.deliveryLocation?.address ?? NOT_AVAILABLE,
				)}
				{renderItemContent(
					"Destination Date/Time",
					item?.deliveryDate
						? formatDate(new Date(item.deliveryDate))
						: NOT_AVAILABLE,
				)}
				{renderItemContent(
					"Special Requirements",
					item.constraints?.length ? item.constraints : NOT_AVAILABLE,
				)}
				{renderItemContent(
					"Revenue",
					item?.revenue
						? getFormattedCurrency(item.revenue, item.currency?.code)
						: NOT_AVAILABLE,
				)}
				{renderItemContent(
					"Quantity",
					item?.quantity
						? getFormattedValue(item.quantity, item.currency?.code)
						: NOT_AVAILABLE,
				)}
				{renderItemContent(
					"Weight",
					item?.weight
						? `${getFormattedValue(item.weight, item.currency?.code)} kg`
						: NOT_AVAILABLE,
				)}
				{renderItemContent("Notes", item?.notes ?? NOT_AVAILABLE, false)}
			</div>
		);
	};

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<span className={styles.header__title}>Goods</span>
			</div>
			{loading ? (
				<div className={styles.loader}>
					<CelerumLoader visible />
				</div>
			) : (
				<div className={styles.content}>
					{goods?.map((item: IGoodsResponseDto) => renderItem(item))}
				</div>
			)}
		</div>
	);
};

import type { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import {
	Field,
	Form,
	FormElement,
	type FormProps,
} from "@progress/kendo-react-form";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { addAttachmentsAction } from "../../../../../../attachments/data-access/src/lib/attachments.slice";
import { AttachmentUsage } from "../../../../../../common/models/src/lib/enums/attachment.enum";
import type {
	IQualification,
	IQualificationRequestDto,
} from "../../../../../../common/models/src/lib/interfaces/qualification.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumFormDatePicker,
	CelerumFormInput,
	CelerumFormMultiSelect,
	CelerumFormUploadInput,
} from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import { fetchConstraintsAction } from "../../../../../../constraints/data-access/src/lib/constraints.slice";
import {
	attachDocumentsToQualificationAction,
	createQualificationAction,
	updateQualificationAction,
} from "../../../../../data-access/src/lib/drivers.slice";
import styles from "./qualification-form.module.scss";

interface QualificationFormProps {
	formState?: IQualification;
	onClose: () => void;
}

export const QualificationForm = ({
	onClose,
	formState,
}: QualificationFormProps) => {
	const { id } = useParams();
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { loading, constraints } = useAppSelector((state) => ({
		loading: state.drivers.loading.qualifications,
		constraints: state.constraints.data,
	}));

	const [constraintIds, setConstraintIds] = useState<number[]>(
		formState?.constraints?.map((item) => item.id) || [],
	);

	const onConstraintsDropdownChange = (event: MultiSelectChangeEvent) => {
		const selectedConstraints = event.target.value;
		setConstraintIds(selectedConstraints.map((item) => item.id));
	};

	const handleSubmit: FormProps["onSubmit"] = async (dataItem) => {
		const { name, dateTaken, expirationDate, files } = dataItem;

		const formData = new FormData();
		if (files?.length) {
			for (const file of files) {
				formData.append("files", file.getRawFile?.() || new Blob());
			}
		}

		const payload = {
			id: formState?.id,
			driverId: Number(id),
			name,
			dateTaken,
			expirationDate,
			constraintIds,
		} as IQualificationRequestDto;

		if (formState) {
			const actionResult = await dispatchWithNotifications({
				action: updateQualificationAction,
				payload,
				successMessage: `Qualification named ${formState.name} successfully updated.`,
				errorMessage: `Could not update qualification named ${formState.name}.`,
			});

			updateQualificationAction.fulfilled.match(actionResult) && onClose();
		} else {
			const actionResult = await dispatchWithNotifications({
				action: createQualificationAction,
				payload,
				successMessage: `Qualification named ${name} successfully created.`,
				errorMessage: `Could not create qualification named ${name}.`,
			});

			if (createQualificationAction.fulfilled.match(actionResult)) {
				if (files?.length > 0) {
					const actionResultAttachments = await dispatch(
						addAttachmentsAction({
							documents: formData,
							entity: AttachmentUsage.Qualification,
							entityId: actionResult.payload.id,
						}),
					);

					if (addAttachmentsAction.fulfilled.match(actionResultAttachments)) {
						dispatch(
							attachDocumentsToQualificationAction({
								documents: actionResultAttachments.payload,
								entityId: actionResult.payload.id,
							}),
						);
					}
				}
				onClose();
			}
		}
	};

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchConstraintsAction,
			payload: {},
			errorMessage: "Could not fetch constraints.",
		});
	}, [dispatchWithNotifications]);

	return (
		<Form
			initialValues={formState}
			onSubmit={handleSubmit}
			render={(formRenderProps) => (
				<FormElement>
					<fieldset className="k-form-fieldset">
						<div className={styles.container}>
							<div>
								<Field
									id="name"
									name="name"
									label="Name"
									component={CelerumFormInput}
									maxLength={150}
									validator={requiredValidator}
									required
									focused="true"
								/>
								<Field
									id="dateTaken"
									name="dateTaken"
									label="Date Taken"
									component={CelerumFormDatePicker}
									validator={requiredValidator}
									required
								/>
								<Field
									id="expirationDate"
									name="expirationDate"
									label="Expiration Date"
									component={CelerumFormDatePicker}
									validator={requiredValidator}
									required
								/>
							</div>
							<div>
								<Field
									id="constraints"
									name="constraints"
									label="Constraints Satisfied"
									dataItemKey="id"
									textField="name"
									data={constraints}
									onChange={onConstraintsDropdownChange}
									component={CelerumFormMultiSelect}
								/>
								{!formState && (
									<Field
										label="Upload Documents"
										id="files"
										name="files"
										component={CelerumFormUploadInput}
									/>
								)}
							</div>
						</div>
					</fieldset>
					<CelerumSubmitButton
						type="submit"
						disabled={!formRenderProps.allowSubmit || loading}
						title="Submit"
					/>
				</FormElement>
			)}
		/>
	);
};

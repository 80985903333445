import { Button } from "@progress/kendo-react-buttons";
import { type CellChange, ReactGrid, type Row } from "@silevis/reactgrid";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import type { MultiEdit, MultiEditType } from "../api/JobApi";
import { DFlex } from "../display/DFlex";
import { jobApi, toasted } from "../helpers";
import { useDialog } from "../helpersReact";

const columns = [
	{ columnId: "name", width: 300 },
	{ columnId: "startLocationId", width: 150 },
	{ columnId: "endLocationId", width: 150 },
	{ columnId: "startDate", width: 110 },
	{ columnId: "startTime", width: 90 },
	{ columnId: "endDate", width: 110 },
	{ columnId: "endTime", width: 90 },
];

type Entity = {
	id: number;
	type: number;
	name: string;
	startLocationId?: string;
	endLocationId?: string;
	startDate?: Date;
	startTime?: Date;
	endDate?: Date;
	endTime?: Date;
	startLocationIdIsOpen?: boolean;
	endLocationIdIsOpen?: boolean;
};

const timeFormat = new Intl.DateTimeFormat("en-GB", {
	hour: "numeric",
	minute: "numeric",
});

// biome-ignore lint/suspicious/noExplicitAny: any is used to represent the previous state
const applyChanges = (changes: CellChange[], previous: any): Entity[] => {
	for (const change of changes) {
		const index = change.rowId;
		const field = change.columnId;
		const newC = change.newCell;
		const oldC = change.previousCell;
		if (newC.type === "text") {
			previous[index][field] = newC.text;
		} else if (newC.type === "date") {
			previous[index][field] = newC.date;
		} else if (newC.type === "dropdown" && oldC.type === "dropdown") {
			if (newC.selectedValue !== oldC.selectedValue) {
				previous[index][field] = newC.selectedValue;
			}
			if (newC.isOpen !== oldC.isOpen) {
				previous[index][`${field}IsOpen`] = newC.isOpen;
			}
		} else if (newC.type === "time") {
			previous[index][field] = newC.time;
		} else {
			throw new Error("Invalid cell type");
		}
	}
	return [...previous];
};

type MultiEditFormProps = {
	locations: { value: string; label: string }[];
	entities: Entity[];
	onSubmitClicked: (entities: Entity[]) => void;
};
const MultiEditForm = ({
	locations,
	entities,
	onSubmitClicked,
}: MultiEditFormProps) => {
	const [data, setData] = useState(entities);
	const rows = useMemo(
		() => [
			{
				rowId: "header",
				cells: [
					{ type: "header", text: "Name" },
					{ type: "header", text: "Start Location" },
					{ type: "header", text: "End Location" },
					{ type: "header", text: "Start Date" },
					{ type: "header", text: "Start Time" },
					{ type: "header", text: "End Date" },
					{ type: "header", text: "End Time" },
				],
			},
			...data.map<Row>((entity, rowId) => ({
				rowId,
				cells: [
					{ type: "text", text: entity.name, nonEditable: true },
					{
						type: "dropdown",
						selectedValue: entity.startLocationId,
						values: locations,
						isOpen: entity.startLocationIdIsOpen,
					},
					{
						type: "dropdown",
						selectedValue: entity.endLocationId,
						values: locations,
						isOpen: entity.endLocationIdIsOpen,
					},
					{ type: "date", date: entity.startDate },
					{ type: "time", time: entity.startTime, format: timeFormat },
					{ type: "date", date: entity.endDate },
					{ type: "time", time: entity.endTime, format: timeFormat },
				],
			})),
		],
		[data, locations],
	);

	const handleDataChange = (x: CellChange[]) =>
		setData((y) => applyChanges(x, y));
	return (
		<div style={{ minHeight: 500 }}>
			<DFlex column>
				<ReactGrid
					rows={rows}
					columns={columns}
					onCellsChanged={handleDataChange}
					enableFillHandle
					enableRangeSelection
				/>
				<DFlex flexEnd>
					<Button onClick={() => onSubmitClicked(data)} themeColor="primary">
						Submit
					</Button>
				</DFlex>
			</DFlex>
		</div>
	);
};

type MultiEditFormWithDTOProps = {
	jobId?: number;
	loadId?: number;
	onSubmitClicked?: (entities: Entity[]) => void;
};
const MultiEditFormWithDTO = (props: MultiEditFormWithDTOProps) => {
	const locations = useQuery({
		queryKey: ["jobApi.location.locationLookupList"],
		queryFn: () =>
			jobApi.location
				.locationLookupList()
				.then((x) =>
					x.data.map((x) => ({ value: `${x.id}`, label: x.name ?? "N/A" })),
				),
		initialData: [],
	});
	const data = useQuery({
		queryKey: ["entities"],
		queryFn: () =>
			jobApi.bff.bffGetMultiEditsCreate(props).then((x) =>
				x.data.map<Entity>((x) => ({
					id: x.id,
					type: x.type,
					name: x.name ?? "N/A",
					startLocationId: x.startLocationId
						? `${x.startLocationId}`
						: undefined,
					endLocationId: x.endLocationId ? `${x.endLocationId}` : undefined,
					startDate: x.startDate ? new Date(x.startDate) : undefined,
					startTime: x.startDate ? new Date(x.startDate) : undefined,
					endDate: x.endDate ? new Date(x.endDate) : undefined,
					endTime: x.endDate ? new Date(x.endDate) : undefined,
				})),
			),
		initialData: [],
	});
	if (!data.isFetchedAfterMount) return "Loading...";
	return (
		<MultiEditForm
			entities={data.data}
			locations={locations.data}
			onSubmitClicked={async (x) => {
				const data = x.map<MultiEdit>((x) => {
					const startDate = x.startDate;
					if (startDate && x.startTime) {
						startDate.setHours(x.startTime.getHours());
						startDate.setMinutes(x.startTime.getMinutes());
					}
					const endDate = x.endDate;
					if (endDate && x.endTime) {
						endDate.setHours(x.endTime.getHours());
						endDate.setMinutes(x.endTime.getMinutes());
					}
					return {
						id: x.id,
						type: x.type as MultiEditType,
						name: x.name,
						startLocationId: x.startLocationId ? +x.startLocationId : undefined,
						endLocationId: x.endLocationId ? +x.endLocationId : undefined,
						startDate: startDate?.toISOString(),
						endDate: endDate?.toISOString(),
					};
				});
				await toasted(jobApi.bff.bffSaveMultiEditCreate(data), "Saving");
				props.onSubmitClicked?.(x);
			}}
		/>
	);
};

export const useMultiEditDialog = () => {
	const [props, setProps] = useState<MultiEditFormWithDTOProps>({});
	const [_toggleDialog, dialog] = useDialog(
		<MultiEditFormWithDTO
			{...props}
			onSubmitClicked={(x) => {
				_toggleDialog();
				props.onSubmitClicked?.(x);
			}}
		/>,
		"Multi Edit",
	);

	const toggleDialog = (props?: MultiEditFormWithDTOProps) => {
		props && setProps(props);
		_toggleDialog();
	};

	return [toggleDialog, dialog] as const;
};

import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import {
	NEW_TRAILER_STATE,
	TRAILERS_FEATURE_KEY,
} from "../../../../common/models/src/lib/constants/trailer.constants";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	IDocument,
	IEntityDocumentDto,
} from "../../../../common/models/src/lib/interfaces/document.interface";
import type {
	ITrailerRequestDto,
	ITrailerResponseDto,
	TrailerState,
} from "../../../../common/models/src/lib/interfaces/trailer.interface";
import {
	createTrailer,
	deleteTrailer,
	fetchTrailers,
	updateTrailer,
} from "./trailers-data-access";

const initialState: TrailerState = {
	data: [],
	total: 0,
	aggregateResults: null,
	loading: false,
	errors: null,
};

export const fetchTrailersAction = createAsyncThunk(
	"jobs/fetchTrailers",
	(params: CelerumQueryParams = {}) => {
		return fetchTrailers(params);
	},
);

export const createTrailerAction = createAsyncThunk(
	"jobs/createTrailer",
	(trailer: ITrailerRequestDto) => {
		return createTrailer(trailer);
	},
);

export const updateTrailerAction = createAsyncThunk(
	"jobs/updateTrailer",
	(trailer: ITrailerRequestDto) => {
		return updateTrailer(trailer);
	},
);

export const deleteTrailerAction = createAsyncThunk(
	"jobs/deleteTrailer",
	(id: number) => {
		return deleteTrailer(id);
	},
);

const trailersSlice = createSlice({
	name: TRAILERS_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		attachDocumentsToTrailerAction: (
			state: TrailerState,
			action: PayloadAction<IEntityDocumentDto>,
		) => {
			state.data = state.data.map((item: ITrailerResponseDto) => {
				if (item.id === action.payload.entityId) {
					item.documents = [...item.documents, ...action.payload.documents];
				}
				return item;
			});
		},
		deleteDocumentsFromTrailerAction: (
			state: TrailerState,
			action: PayloadAction<number>,
		) => {
			state.data = state.data.map((item: ITrailerResponseDto) => {
				item.documents = item.documents.filter(
					(document: IDocument) => document.id !== action.payload,
				);
				return item;
			});
		},
		clearTrailersAction: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchTrailersAction.pending, (state: TrailerState) => {
				state.loading = true;
			})
			.addCase(createTrailerAction.pending, (state: TrailerState) => {
				state.loading = true;
			})
			.addCase(updateTrailerAction.pending, (state: TrailerState) => {
				state.loading = true;
			})
			.addCase(deleteTrailerAction.pending, (state: TrailerState) => {
				state.loading = true;
			})
			/** Fulfilled */
			.addCase(
				fetchTrailersAction.fulfilled,
				(state: TrailerState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults, page, pageSize } =
						action.payload;
					const trailers = data as ITrailerResponseDto[];

					/** Check if the data is already fetched
					 * If it is, just update the data
					 * If it is not, fill the array with NEW_TRAILER_STATE
					 */
					const newTrailers =
						state.data.length === total
							? [...state.data]
							: new Array(total).fill(NEW_TRAILER_STATE);

					/** Fill newTrailers with the fetched data */
					trailers.forEach((trailer, i) => {
						if (page && pageSize) {
							const index = (page - 1) * pageSize + i;
							newTrailers[index] = {
								...newTrailers[index],
								...trailer,
							};
						}
					});

					state.total = total;
					state.data = newTrailers;
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			.addCase(
				createTrailerAction.fulfilled,
				(state: TrailerState, action: PayloadAction<ITrailerResponseDto>) => {
					state.data = [action.payload, ...state.data];
					state.loading = false;
					state.total += 1;
				},
			)
			.addCase(
				updateTrailerAction.fulfilled,
				(state: TrailerState, action: PayloadAction<ITrailerResponseDto>) => {
					const index = state.data.findIndex(
						(trailer) => trailer.id === action.payload.id,
					);
					state.data[index] = action.payload;
					state.loading = false;
				},
			)
			.addCase(
				deleteTrailerAction.fulfilled,
				(state: TrailerState, action: PayloadAction<number | null>) => {
					state.data = state.data.filter(
						(trailer) => trailer.id !== action.payload,
					);
					state.loading = false;
					state.total -= 1;
				},
			)
			/** Rejected */
			.addCase(fetchTrailersAction.rejected, (state: TrailerState) => {
				state.loading = false;
			})
			.addCase(createTrailerAction.rejected, (state: TrailerState) => {
				state.loading = false;
			})
			.addCase(updateTrailerAction.rejected, (state: TrailerState) => {
				state.loading = false;
			})
			.addCase(deleteTrailerAction.rejected, (state: TrailerState) => {
				state.loading = false;
			});
	},
});

/** Reducer */
export const trailersReducer = trailersSlice.reducer;

/** Actions */
export const {
	attachDocumentsToTrailerAction,
	deleteDocumentsFromTrailerAction,
	clearTrailersAction,
} = trailersSlice.actions;

import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import { TRAILER_TYPES_FEATURE_KEY } from "../../../../common/models/src/lib/constants/trailer-types.constants";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	ITrailerType,
	TrailerTypesState,
} from "../../../../common/models/src/lib/interfaces/trailer-type.interface";
import {
	createTrailerType,
	deleteTrailerType,
	fetchTrailerTypes,
	updateTrailerType,
} from "./trailer-types-data-access";

const initialState: TrailerTypesState = {
	data: [],
	total: 0,
	aggregateResults: null,
	loading: false,
	errors: null,
};

export const fetchTrailerTypesAction = createAsyncThunk(
	"trailerTypes/fetchTrailerTypes",
	(params: CelerumQueryParams = {}) => {
		return fetchTrailerTypes(params);
	},
);

export const createTrailerTypeAction = createAsyncThunk(
	"trailerTypes/createTrailerType",
	(trailerType: ITrailerType) => {
		return createTrailerType(trailerType);
	},
);

export const updateTrailerTypeAction = createAsyncThunk(
	"trailerTypes/updateTrailerType",
	(trailerType: ITrailerType) => {
		return updateTrailerType(trailerType);
	},
);

export const deleteTrailerTypeAction = createAsyncThunk(
	"trailerTypes/deleteTrailerType",
	(id: number) => {
		return deleteTrailerType(id);
	},
);

const trailerTypesSlice = createSlice({
	name: TRAILER_TYPES_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		clearTrailerTypesAction: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchTrailerTypesAction.pending, (state: TrailerTypesState) => {
				state.loading = true;
			})
			.addCase(deleteTrailerTypeAction.pending, (state: TrailerTypesState) => {
				state.loading = true;
			})
			.addCase(createTrailerTypeAction.pending, (state: TrailerTypesState) => {
				state.loading = true;
			})
			.addCase(updateTrailerTypeAction.pending, (state: TrailerTypesState) => {
				state.loading = true;
			})
			/** Fulfilled */
			.addCase(
				fetchTrailerTypesAction.fulfilled,
				(state: TrailerTypesState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults } = action.payload;
					state.data = (data as ITrailerType[]).map((trailerType) => ({
						...trailerType,
						vehicleType: "TRAILER",
						uniqueId: `${trailerType.id}-${trailerType.name}`,
					}));
					state.total = total;
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			.addCase(
				createTrailerTypeAction.fulfilled,
				(state: TrailerTypesState, action: PayloadAction<ITrailerType>) => {
					state.data = [action.payload, ...state.data];
					state.loading = false;
					state.total += 1;
				},
			)
			.addCase(
				updateTrailerTypeAction.fulfilled,
				(state: TrailerTypesState, action: PayloadAction<ITrailerType>) => {
					const index = state.data.findIndex(
						(trailer) => trailer.id === action.payload.id,
					);
					state.data[index] = action.payload;
					state.loading = false;
				},
			)
			.addCase(
				deleteTrailerTypeAction.fulfilled,
				(state: TrailerTypesState, action: PayloadAction<number | null>) => {
					state.data = state.data.filter(
						(trailer) => trailer.id !== action.payload,
					);
					state.loading = false;
					state.total -= 1;
				},
			)
			/** Rejected */
			.addCase(fetchTrailerTypesAction.rejected, (state: TrailerTypesState) => {
				state.loading = false;
			})
			.addCase(createTrailerTypeAction.rejected, (state: TrailerTypesState) => {
				state.loading = false;
			})
			.addCase(updateTrailerTypeAction.rejected, (state: TrailerTypesState) => {
				state.loading = false;
			})
			.addCase(deleteTrailerTypeAction.rejected, (state: TrailerTypesState) => {
				state.loading = false;
			});
	},
});

/** Reducer */
export const trailerTypesReducer = trailerTypesSlice.reducer;

/** Actions */
export const { clearTrailerTypesAction } = trailerTypesSlice.actions;

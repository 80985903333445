import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import { CURRENCY_EXCHANGE_RATES_FEATURE_KEY } from "../../../../common/models/src/lib/constants/currencies.constants";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	CurrencyExchangeRatesState,
	ICurrencyExchangeRate,
	ICurrencyExchangeRateRequestDto,
} from "../../../../common/models/src/lib/interfaces/currency-exchange-rate.interface";
import {
	createCurrencyExchangeRate,
	deleteCurrencyExchangeRate,
	fetchCurrencyExchangeRates,
	updateCurrencyExchangeRate,
} from "./currency-exchange-rate-data-access";

const initialState: CurrencyExchangeRatesState = {
	data: [],
	loading: false,
	total: 0,
	aggregateResults: null,
	errors: null,
};

export const fetchCurrencyExchangeRatesAction = createAsyncThunk(
	"currencyExchangeRates/fetchCurrencyExchangeRates",
	async () => {
		return fetchCurrencyExchangeRates();
	},
);

export const createCurrencyExchangeRateAction = createAsyncThunk(
	"currencyExchangeRates/createCurrencyExchangeRate",
	async (currencyExchangeRate: ICurrencyExchangeRateRequestDto) => {
		return createCurrencyExchangeRate(currencyExchangeRate);
	},
);

export const updateCurrencyExchangeRateAction = createAsyncThunk(
	"currencyExchangeRates/updateCurrencyExchangeRate",
	async (currencyExchangeRate: ICurrencyExchangeRateRequestDto) => {
		return updateCurrencyExchangeRate(currencyExchangeRate);
	},
);

export const deleteCurrencyExchangeRateAction = createAsyncThunk(
	"currencyExchangeRates/deleteCurrencyExchangeRate",
	async (id: number) => {
		return deleteCurrencyExchangeRate(id);
	},
);

const currencyExchangeRatesSlice = createSlice({
	name: CURRENCY_EXCHANGE_RATES_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		clearCurrencyExchangeRatesAction: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(
				fetchCurrencyExchangeRatesAction.pending,
				(state: CurrencyExchangeRatesState) => {
					state.loading = true;
				},
			)
			.addCase(
				createCurrencyExchangeRateAction.pending,
				(state: CurrencyExchangeRatesState) => {
					state.loading = true;
				},
			)
			.addCase(
				updateCurrencyExchangeRateAction.pending,
				(state: CurrencyExchangeRatesState) => {
					state.loading = true;
				},
			)
			.addCase(
				deleteCurrencyExchangeRateAction.pending,
				(state: CurrencyExchangeRatesState) => {
					state.loading = true;
				},
			)
			/** Fulfilled */
			.addCase(
				fetchCurrencyExchangeRatesAction.fulfilled,
				(
					state: CurrencyExchangeRatesState,
					action: PayloadAction<CelerumResponse>,
				) => {
					const { data, total } = action.payload;
					state.data = data as ICurrencyExchangeRate[];
					state.total = total;
					state.loading = false;
				},
			)
			.addCase(
				createCurrencyExchangeRateAction.fulfilled,
				(
					state: CurrencyExchangeRatesState,
					action: PayloadAction<ICurrencyExchangeRate>,
				) => {
					state.data = [action.payload, ...state.data];
					state.loading = false;
					state.total += 1;
				},
			)
			.addCase(
				updateCurrencyExchangeRateAction.fulfilled,
				(
					state: CurrencyExchangeRatesState,
					action: PayloadAction<ICurrencyExchangeRate>,
				) => {
					const index = state.data.findIndex(
						(currencyExchangeRate) =>
							currencyExchangeRate.id === action.payload.id,
					);
					state.data[index] = action.payload;
					state.loading = false;
				},
			)
			.addCase(
				deleteCurrencyExchangeRateAction.fulfilled,
				(
					state: CurrencyExchangeRatesState,
					action: PayloadAction<number | null>,
				) => {
					state.data = state.data.filter(
						(currencyExchangeRate) =>
							currencyExchangeRate.id !== action.payload,
					);
					state.loading = false;
					state.total -= 1;
				},
			)
			/** Rejected */
			.addCase(
				fetchCurrencyExchangeRatesAction.rejected,
				(state: CurrencyExchangeRatesState) => {
					state.loading = true;
				},
			)
			.addCase(
				createCurrencyExchangeRateAction.rejected,
				(state: CurrencyExchangeRatesState) => {
					state.loading = true;
				},
			)
			.addCase(
				updateCurrencyExchangeRateAction.rejected,
				(state: CurrencyExchangeRatesState) => {
					state.loading = true;
				},
			)
			.addCase(
				deleteCurrencyExchangeRateAction.rejected,
				(state: CurrencyExchangeRatesState) => {
					state.loading = true;
				},
			);
	},
});

/** Reducer */
export const currencyExchangeRatesReducer = currencyExchangeRatesSlice.reducer;

/** Action */
export const { clearCurrencyExchangeRatesAction } =
	currencyExchangeRatesSlice.actions;

import { SvgIcon } from "@progress/kendo-react-common";
import cls from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { chevronIcon } from "../../../../../models/src/lib/constants/icon.constants";
import { CelerumPopup } from "../celerum-popup/celerum-popup.component";
import styles from "./celerum-interactive-status.module.scss";

interface StatusMapping {
	title: string;
	className: string;
}

interface CelerumInteractiveStatusProps {
	status: number;
	isInteractive?: boolean;
	statusMappings?: {
		[key: number]: StatusMapping;
	};
	availableStatuses?: number[];
	onChange?: (status: number) => void;
}

export const CelerumInteractiveStatus = ({
	status,
	isInteractive = true,
	availableStatuses,
	statusMappings,
	onChange,
}: CelerumInteractiveStatusProps) => {
	const [currentStatus, setCurrentStatus] = useState<number>(status);
	const [openStatuses, setOpenStatuses] = useState<boolean>(false);

	const anchor = useRef<HTMLDivElement>(null);

	const getClassName = useCallback(
		(status: number, baseClassName: string) => {
			if (statusMappings?.[status]) {
				return cls(
					baseClassName,
					styles[`status__${statusMappings[status]?.className}`],
				);
			}
			return cls(baseClassName, styles.status__gray);
		},
		[statusMappings],
	);

	const handleChangeStatus = (status: number) => {
		onChange?.(status);
		setOpenStatuses(false);
	};

	const handleStatusClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (isInteractive) {
			event.stopPropagation();
			setOpenStatuses(!openStatuses);
		}
	};

	const renderTitle = (status: number) => {
		if (statusMappings?.[status]) {
			return statusMappings[status]?.title;
		}
		return "Unknown";
	};

	useEffect(() => {
		setCurrentStatus(status);
	}, [status]);

	useEffect(() => {
		const isOutsidePopup = (target: EventTarget | null) => {
			if (!target || !(target instanceof HTMLElement)) {
				return true;
			}

			return !target.className.includes(
				"celerum-interactive-status_status__popup",
			);
		};

		const handleClickOutside = (event: MouseEvent) => {
			if (
				isOutsidePopup(event.target) &&
				anchor.current &&
				!anchor.current.contains(event.target as Node)
			) {
				setOpenStatuses(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className={styles.status}>
			<div
				className={getClassName(
					currentStatus,
					cls(
						styles.status__anchor,
						isInteractive && styles.status__anchor__interactive,
					),
				)}
				ref={anchor}
				onClick={handleStatusClick}
				onKeyUp={() => 0}
			>
				{renderTitle(currentStatus)}
				{isInteractive && (
					<SvgIcon
						icon={chevronIcon}
						className={
							openStatuses
								? styles.status__chevronUp
								: styles.status__chevronDown
						}
					/>
				)}
			</div>
			<CelerumPopup
				show={openStatuses}
				anchor={anchor}
				appendTo={anchor.current}
			>
				<div
					className={styles.status__popup}
					onClick={(e) => e.stopPropagation()}
					onKeyUp={(e) => e.stopPropagation()}
				>
					{availableStatuses?.map((status) => (
						<div
							className={styles.status__popup__item}
							key={status}
							onClick={() => handleChangeStatus(status)}
							onKeyUp={() => handleChangeStatus(status)}
						>
							<span
								className={getClassName(
									status,
									styles.status__popup__item__dot ?? "",
								)}
							/>
							<span className={styles.status__popup__item__label}>
								{renderTitle(status)}
							</span>
						</div>
					))}
				</div>
			</CelerumPopup>
		</div>
	);
};

import type { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import { type Dispatch, type SetStateAction, useState } from "react";
import { SUPPLIER_INVOICES } from "../../../../../../../../common/models/src/lib/constants/invoice.constants";
import type { IBase } from "../../../../../../../../common/models/src/lib/interfaces/base.interface";
import { useAppSelector } from "../../../../../../../../common/stores/src/lib/utils";
import {
	CelerumFormDatePicker,
	CelerumFormDropDownList,
	CelerumFormInput,
	CelerumFormMultiSelect,
	CelerumFormTextArea,
	CelerumFormUploadInput,
} from "../../../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import {
	noNegativeValidator,
	requiredValidator,
} from "../../../../../../../../common/utils/src/lib/validators/validators";
import styles from "../../leg-form.module.scss";

interface BlankFieldsProps {
	goods: IBase[];
	updateMode: boolean;
	setGoodsIds: Dispatch<SetStateAction<number[]>>;
}

export const BlankFields = ({
	goods,
	setGoodsIds,
	updateMode,
}: BlankFieldsProps) => {
	const [cost, setCost] = useState<number | undefined>(undefined);

	const { data: currencies } = useAppSelector((state) => state.currencies);

	const onGoodsDropdownChange = (event: MultiSelectChangeEvent) => {
		const selectedGoods = event.target.value;
		setGoodsIds(selectedGoods.map((item: IBase) => item.id));
	};

	return (
		<>
			<Field
				id="goods"
				label="Goods"
				name="goods"
				dataItemKey="id"
				textField="name"
				data={goods}
				onChange={onGoodsDropdownChange}
				component={CelerumFormMultiSelect}
				validator={requiredValidator}
				required
			/>
			<div className={styles.currency}>
				<div className={styles.currency__cost}>
					<Field
						id="cost"
						label="Cost"
						name="cost"
						component={CelerumFormInput}
						type="number"
						onChange={(event) => {
							const value = Number.parseFloat(event.target.value);
							setCost(Number.isNaN(value) ? undefined : value);
						}}
						validator={
							cost !== undefined && cost < 0 ? noNegativeValidator : undefined
						}
					/>
				</div>
				<div className={styles.currency__code}>
					<Field
						id="currency"
						dataItemKey="id"
						label="Currency"
						textField="code"
						name="currency"
						component={CelerumFormDropDownList}
						data={currencies}
						validator={
							cost !== undefined && cost >= 0 ? requiredValidator : undefined
						}
						required={cost !== undefined ? cost >= 0 : false}
					/>
				</div>
			</div>
			<Field
				id="supplierInvoiceNumber"
				label="Supplier Invoice Number"
				name="supplierInvoiceNumber"
				maxLength={500}
				component={CelerumFormInput}
			/>
			<Field
				id="supplierInvoice"
				label="Supplier Invoice"
				name="supplierInvoice"
				dataItemKey="id"
				textField="name"
				component={CelerumFormDropDownList}
				data={SUPPLIER_INVOICES}
				defaultValue={SUPPLIER_INVOICES[1]}
			/>
			<Field
				id="supplierInvoiceDate"
				label="Supplier Invoice Date"
				name="supplierInvoiceDate"
				component={CelerumFormDatePicker}
			/>
			<Field
				id="note"
				label="Notes"
				name="notes"
				maxLength={500}
				component={CelerumFormTextArea}
				type="text"
				validator={requiredValidator}
				required
			/>
			{!updateMode && (
				<Field
					label="Upload Documents"
					id="files"
					name="files"
					component={CelerumFormUploadInput}
				/>
			)}
		</>
	);
};

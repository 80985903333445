import { TextArea } from "@progress/kendo-react-inputs";
import { useMutation, useQuery } from "@tanstack/react-query";
import { isNil, omitBy } from "lodash";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { GLOBALS } from "../_GLOBALS";
import type { LegFormDataResponse } from "../api/JobApi";
import { type Leg, LegForm } from "../forms/LegForm";
import {
	NewLegType,
	NewSupplierInvoiceType,
	jobApi,
	toasted,
	useLookupBusinessUnits,
	useLookupCurrencies,
	useLookupDrivers,
	useLookupGoods,
	useLookupLegTypes,
	useLookupLocations,
	useLookupSubcontractors,
	useLookupSupplierInvoiceTypes,
	useLookupTrailerTypes,
	useLookupTrailers,
	useLookupTruckTypes,
	useLookupTrucks,
} from "../helpers";
import { useDialog } from "../helpersReact";
import {
	type Recommendation,
	useRecommendationsDialog,
} from "./useRecommendationsDialog";

type LegSelfFormProps = {
	id?: number;
	jobId?: number;
	onSubmit?: (data: Leg) => void;
	isRecEnabled?: boolean;
	onRecommendationsButtonClick?: () => void;
	onChange?: (data: Leg) => void;
};
const LegSelfForm = ({
	id,
	jobId,
	onSubmit,
	onRecommendationsButtonClick,
	isRecEnabled,
	onChange,
}: LegSelfFormProps) => {
	const data = useQuery({
		queryKey: ["jobApi.bff.bffLegFormDataDetail", id, jobId],
		queryFn: () => {
			if (jobId) {
				return {
					jobId,
					goodsIds: [],
					legType: NewLegType.Delivery,
					supplierInvoice: NewSupplierInvoiceType.NoInvoice,
				} satisfies LegFormDataResponse;
			}
			return jobApi.bff
				.bffLegFormDataDetail(Number(id))
				.then((data) => data.data);
		},
	});
	const update = useMutation({
		mutationKey: ["jobApi.bff.bffLegFormDataUpdate"],
		mutationFn: (data: LegFormDataResponse) =>
			jobApi.bff.bffLegFormDataCreate(data),
	});
	const lLegTypes = useLookupLegTypes();
	const lGoods = useLookupGoods(data.data?.jobId ?? undefined);
	const lLocations = useLookupLocations();
	const lSubcontractors = useLookupSubcontractors();
	const lDrivers = useLookupDrivers();
	const lTrucks = useLookupTrucks();
	const lTrailers = useLookupTrailers();
	const lBusinessUnits = useLookupBusinessUnits();
	const lTrailerTypes = useLookupTrailerTypes();
	const lTruckTypes = useLookupTruckTypes();
	const lSupplierInvoiceTypes = useLookupSupplierInvoiceTypes();
	const lCurrencies = useLookupCurrencies();
	if (!data.isFetchedAfterMount || !data.data?.jobId) {
		return <div>Loading...</div>;
	}
	return (
		<LegForm
			isRecEnabled={isRecEnabled}
			onRecommendationsButtonClick={onRecommendationsButtonClick}
			lLegTypes={lLegTypes}
			lGoods={lGoods}
			lLocations={lLocations}
			lSubcontractors={lSubcontractors}
			lDrivers={lDrivers}
			lTrucks={lTrucks}
			lTrailers={lTrailers}
			lBusinessUnits={lBusinessUnits}
			lTrailerTypes={lTrailerTypes}
			lTruckTypes={lTruckTypes}
			lSupplierInvoiceTypes={lSupplierInvoiceTypes}
			lCurrencies={lCurrencies}
			defaultValues={omitBy(data.data, isNil)}
			onSubmit={async (data) => {
				await toasted(
					update.mutateAsync(data as LegFormDataResponse),
					"Saving leg",
				);
				onSubmit?.(data);
			}}
			onChange={onChange}
		/>
	);
};

export const useLegFormDialog = () => {
	const [props, setProps] = useState<LegSelfFormProps>();
	const [formData, setFormData] = useState<Leg>();
	const [recommendation, setRecommendation] = useState<Recommendation>();
	const handleRecommendationSelected = (x: Recommendation) => {
		const form = GLOBALS.legForm;
		setRecommendation(x);
		if (!form) return console.error("Form not found");
		form.setValue("driverId", x.driverId ?? null, { shouldDirty: true });
		form.setValue("truckId", x.truckId ?? null, { shouldDirty: true });
		form.setValue("trailerId", x.trailerId ?? null, { shouldDirty: true });
		form.setValue("subcontractorId", x.subcontractorId ?? null, {
			shouldDirty: true,
		});
		toggleRecDialog(false);
	};
	const [toggleRecDialog, recDialog, isRecEnabled] = useRecommendationsDialog(
		formData,
		handleRecommendationSelected,
	);
	const handleSubmit = (data: Leg) => {
		toggleDialog(false);
		const closestDrivers = GLOBALS.recommendationsClosestDrivers;
		const randomDrivers = GLOBALS.recommendationsRandomDrivers;
		GLOBALS.recommendationsClosestDrivers = [];
		GLOBALS.recommendationsRandomDrivers = [];
		const form = GLOBALS.legForm;
		const legId = data.id;
		if (!form) return console.error("Form not found");
		if (!data._isOperationDirty) return console.log("No operation changes");
		if (!legId) return console.error("Leg ID not found");
		const recommendations = { closestDrivers, randomDrivers };
		const selectedRecommendation =
			recommendation?.type === "ClosestDrivers"
				? closestDrivers[recommendation.index]
				: recommendation?.type === "RandomDrivers"
					? randomDrivers[recommendation.index]
					: undefined;
		const isRecommendationChanged =
			(selectedRecommendation?.driverId ?? null) !== (data.driverId ?? null) ||
			(selectedRecommendation?.truckId ?? null) !== (data.truckId ?? null) ||
			(selectedRecommendation?.trailerId ?? null) !== (data.trailerId ?? null);
		let feedback = "";
		toast.info(
			(props) => (
				<>
					<div>
						<h3>Allocation feedback</h3>
						<p>
							Please provide feedback on the allocation. This will help us
							improve the recommendation engine. Close this dialog when you are
							done. Thank you!
						</p>
					</div>
					<TextArea
						rows={5}
						onChange={(x) => {
							toast.update(props.toastProps.toastId, { autoClose: false });
							feedback = x.value;
						}}
					/>
				</>
			),
			{
				onClose: () => {
					jobApi.bff.bffRecommendationFeedbackCreate({
						legId,
						recommendations,
						feedback,
						index: isRecommendationChanged ? undefined : recommendation?.index,
						type: isRecommendationChanged ? undefined : recommendation?.type,
					});
				},
			},
		);
	};
	const [toggleDialog, dialog] = useDialog(
		<div style={{ minWidth: 1000 }}>
			<LegSelfForm
				onSubmit={handleSubmit}
				{...props}
				onRecommendationsButtonClick={toggleRecDialog}
				isRecEnabled={isRecEnabled}
				onChange={setFormData}
			/>
		</div>,
		props?.id ? "Edit Leg" : "Add Leg",
	);
	const openDialog = (props?: LegSelfFormProps) => {
		setRecommendation(undefined);
		setProps(props);
		toggleDialog(props);
	};
	return [
		openDialog,
		<Fragment key="">
			{dialog}
			{recDialog}
		</Fragment>,
	] as const;
};

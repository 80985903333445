import NProgress from "nprogress";
import { useEffect, useState } from "react";
import { TRUCK_TYPES_GRID_COLUMNS } from "../../../../../common/models/src/lib/constants/grid-column.constants";
import {
	ModalSize,
	ModalType,
} from "../../../../../common/models/src/lib/enums/modal.enums";
import type { ITruckType } from "../../../../../common/models/src/lib/interfaces/truck-type.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumGridHeader } from "../../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumModal } from "../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import {
	clearTruckTypesAction,
	deleteTruckTypeAction,
	fetchTruckTypesAction,
} from "../../../../data-access/src/lib/truck-types.slice";
import { TruckTypeForm } from "../components/truck-type-form/truck-type-form.component";

export const TruckTypesFeature = () => {
	const dispatch = useAppDispatch();
	const dispatchWithNotification = useAppDispatchWithNotifications();

	const { data, total, loading } = useAppSelector((state) => state.truckTypes);

	const [showModal, setShowModal] = useState<{
		createOrUpdate: boolean;
		delete: boolean;
	}>({
		createOrUpdate: false,
		delete: false,
	});
	const [selectedTruckType, setSelectedTruckType] = useState<
		ITruckType | undefined
	>(undefined);

	const openCreateModal = () => {
		setShowModal({ ...showModal, createOrUpdate: true });
	};

	const openUpdateModal = (truckType: ITruckType) => {
		setShowModal({ ...showModal, createOrUpdate: true });
		setSelectedTruckType(truckType);
	};

	const openDeleteModal = (truckType: ITruckType) => {
		setShowModal({ ...showModal, delete: true });
		setSelectedTruckType(truckType);
	};

	const closeModal = () => {
		setShowModal({ createOrUpdate: false, delete: false });
		setSelectedTruckType(undefined);
	};

	const handleDeleteTruckType = () => {
		if (!selectedTruckType) return;

		dispatchWithNotification({
			action: deleteTruckTypeAction,
			payload: selectedTruckType.id,
			successMessage: `Truck type named ${selectedTruckType.name} was successfully deleted.`,
			errorMessage: `Could not delete truck type named ${selectedTruckType.name}.`,
		});
		setSelectedTruckType(undefined);
	};

	const requestSortedData = (sort: string) => {
		dispatchWithNotification({
			action: fetchTruckTypesAction,
			payload: { sort },
			errorMessage: "Could not sort truck types.",
		});
	};

	useEffect(() => {
		if (loading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading]);

	useEffect(() => {
		dispatchWithNotification({
			action: fetchTruckTypesAction,
			payload: {},
			errorMessage: "Could not fetch truck types.",
		});

		return () => {
			dispatch(clearTruckTypesAction());
		};
	}, [dispatch, dispatchWithNotification]);

	return (
		<>
			<CelerumGridHeader
				title="Truck Types"
				numberOfItems={total}
				addButtonName="Add Truck Type"
				addButtonDisabled={loading}
				handleOpenAddModal={openCreateModal}
			/>
			<CelerumGrid
				columns={TRUCK_TYPES_GRID_COLUMNS}
				data={data}
				total={total}
				requestSortedData={requestSortedData}
				handleUpdate={openUpdateModal}
				handleDelete={openDeleteModal}
			/>
			<CelerumModal
				title={
					selectedTruckType ? selectedTruckType.name : "Add New Truck Type"
				}
				width={ModalSize.Small}
				toggleDialog={closeModal}
				visible={showModal.createOrUpdate}
			>
				<TruckTypeForm formState={selectedTruckType} onClose={closeModal} />
			</CelerumModal>
			<CelerumConfirmModal
				type={ModalType.Delete}
				subtitle="The truck type will be completely removed and cannot be recovered."
				entityName="truck type"
				entityProperty="named"
				entityValue={selectedTruckType?.name}
				isOpen={showModal.delete}
				handleSubmit={handleDeleteTruckType}
				handleClose={closeModal}
			/>
		</>
	);
};

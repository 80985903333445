import NProgress from "nprogress";
import { useEffect, useState } from "react";
import { LOAD_TYPES_GRID_COLUMNS } from "../../../../../common/models/src/lib/constants/grid-column.constants";
import {
	ModalSize,
	ModalType,
} from "../../../../../common/models/src/lib/enums/modal.enums";
import type { ILoadTypeResponseDto } from "../../../../../common/models/src/lib/interfaces/load-type.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumGridHeader } from "../../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumModal } from "../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import {
	clearLoadTypesAction,
	deleteLoadTypeAction,
	fetchLoadTypesAction,
} from "../../../../data-access/src/lib/load-types.slice";
import { LoadTypeForm } from "../components/load-types-form/load-types-form.component";

export const LoadTypesFeature = () => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const dispatch = useAppDispatch();

	const { data, total, loading } = useAppSelector((state) => state.loadTypes);

	const [showModal, setShowModal] = useState<{
		createOrUpdate: boolean;
		delete: boolean;
	}>({
		createOrUpdate: false,
		delete: false,
	});
	const [selectedLoadType, setSelectedLoadType] = useState<
		ILoadTypeResponseDto | undefined
	>(undefined);

	const openCreateModal = () => {
		setShowModal({ ...showModal, createOrUpdate: true });
	};

	const openUpdateModal = (loadType: ILoadTypeResponseDto) => {
		setShowModal({ ...showModal, createOrUpdate: true });
		setSelectedLoadType(loadType);
	};

	const openDeleteModal = (loadType: ILoadTypeResponseDto) => {
		setShowModal({ ...showModal, delete: true });
		setSelectedLoadType(loadType);
	};

	const closeModal = () => {
		setShowModal({ createOrUpdate: false, delete: false });
	};

	const handleDeleteLoadType = () => {
		if (!selectedLoadType) return;

		dispatchWithNotifications({
			action: deleteLoadTypeAction,
			payload: selectedLoadType.id,
			successMessage: `Load type named ${selectedLoadType.name} successfully deleted.`,
			errorMessage: `Could not delete load type named ${selectedLoadType.name}!`,
		});
		setSelectedLoadType(undefined);
	};

	const requestSortedData = (sort: string) => {
		dispatchWithNotifications({
			action: fetchLoadTypesAction,
			payload: { sort },
			errorMessage: "Could not fetch load types.",
		});
	};

	useEffect(() => {
		if (loading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading]);

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchLoadTypesAction,
			errorMessage: "Could not fetch load types.",
		});

		return () => {
			dispatch(clearLoadTypesAction());
		};
	}, [dispatch, dispatchWithNotifications]);

	return (
		<>
			<CelerumGridHeader
				title="Load Types"
				numberOfItems={total}
				addButtonName="Add Load Type"
				addButtonDisabled={loading}
				handleOpenAddModal={openCreateModal}
			/>
			<CelerumGrid
				columns={LOAD_TYPES_GRID_COLUMNS}
				data={data}
				total={total}
				requestSortedData={requestSortedData}
				handleUpdate={openUpdateModal}
				handleDelete={openDeleteModal}
			/>
			<CelerumModal
				title={selectedLoadType ? selectedLoadType.name : "Add New Load Type"}
				width={ModalSize.Small}
				toggleDialog={closeModal}
				visible={showModal.createOrUpdate}
			>
				<LoadTypeForm formState={selectedLoadType} onClose={closeModal} />
			</CelerumModal>
			<CelerumConfirmModal
				type={ModalType.Delete}
				subtitle="The load type will be completely removed and cannot be recovered."
				entityName="load type"
				entityProperty="named"
				entityValue={selectedLoadType?.name}
				isOpen={showModal.delete}
				handleSubmit={handleDeleteLoadType}
				handleClose={closeModal}
			/>
		</>
	);
};

import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import { CONSTRAINTS_FEATURE_KEY } from "../../../../common/models/src/lib/constants/constraints.constants";
import type { IBase } from "../../../../common/models/src/lib/interfaces/base.interface";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type { ConstraintState } from "../../../../common/models/src/lib/interfaces/constraint.interface";
import {
	createConstraint,
	deleteConstraint,
	fetchConstraints,
	updateConstraint,
} from "./constraints-data-access";

const initialState: ConstraintState = {
	data: [],
	total: 0,
	aggregateResults: null,
	loading: false,
	errors: null,
};

export const fetchConstraintsAction = createAsyncThunk(
	"constraints/fetchConstraints",
	(params: CelerumQueryParams = {}) => {
		return fetchConstraints(params);
	},
);

export const createConstraintAction = createAsyncThunk(
	"constraints/createConstraint",
	(constraint: IBase) => {
		return createConstraint(constraint);
	},
);

export const updateConstraintAction = createAsyncThunk(
	"constraints/updateConstraint",
	(constraint: IBase) => {
		return updateConstraint(constraint);
	},
);

export const deleteConstraintAction = createAsyncThunk(
	"constraints/deleteConstraint",
	(id: number) => {
		return deleteConstraint(id);
	},
);

const constraintsSlice = createSlice({
	name: CONSTRAINTS_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		clearConstraintsAction: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchConstraintsAction.pending, (state: ConstraintState) => {
				state.loading = true;
			})
			.addCase(createConstraintAction.pending, (state: ConstraintState) => {
				state.loading = true;
			})
			.addCase(updateConstraintAction.pending, (state: ConstraintState) => {
				state.loading = true;
			})
			.addCase(deleteConstraintAction.pending, (state: ConstraintState) => {
				state.loading = true;
			})
			/** Fulfilled */
			.addCase(
				fetchConstraintsAction.fulfilled,
				(state: ConstraintState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults } = action.payload;
					state.data = data as IBase[];
					state.total = total;
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			.addCase(
				createConstraintAction.fulfilled,
				(state: ConstraintState, action: PayloadAction<IBase>) => {
					state.data = [action.payload, ...state.data];
					state.loading = false;
					state.total += 1;
				},
			)
			.addCase(
				updateConstraintAction.fulfilled,
				(state: ConstraintState, action: PayloadAction<IBase>) => {
					const index = state.data.findIndex(
						(constraint) => constraint.id === action.payload.id,
					);
					state.data[index] = action.payload;
					state.loading = false;
				},
			)
			.addCase(
				deleteConstraintAction.fulfilled,
				(state: ConstraintState, action: PayloadAction<number | null>) => {
					state.data = state.data.filter(
						(constraint) => constraint.id !== action.payload,
					);
					state.loading = false;
					state.total -= 1;
				},
			)
			/** Rejected */
			.addCase(fetchConstraintsAction.rejected, (state: ConstraintState) => {
				state.loading = false;
			})
			.addCase(createConstraintAction.rejected, (state: ConstraintState) => {
				state.loading = false;
			})
			.addCase(updateConstraintAction.rejected, (state: ConstraintState) => {
				state.loading = false;
			})
			.addCase(deleteConstraintAction.rejected, (state: ConstraintState) => {
				state.loading = false;
			});
	},
});

/** Reducer */
export const constraintsReducer = constraintsSlice.reducer;

export const { clearConstraintsAction } = constraintsSlice.actions;

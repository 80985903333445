import type { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import {
	Field,
	Form,
	FormElement,
	type FormProps,
} from "@progress/kendo-react-form";
import { useEffect, useState } from "react";
import { addAttachmentsAction } from "../../../../../../attachments/data-access/src/lib/attachments.slice";
import { AttachmentUsage } from "../../../../../../common/models/src/lib/enums/attachment.enum";
import type { ITrailerRequestDto } from "../../../../../../common/models/src/lib/interfaces/trailer.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import {
	CelerumDisabledInput,
	CelerumFormDatePicker,
	CelerumFormDropDownList,
	CelerumFormInput,
	CelerumFormMultiSelect,
	CelerumFormTextArea,
	CelerumFormUploadInput,
} from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import {
	attachDocumentsToTrailerAction,
	createTrailerAction,
	updateTrailerAction,
} from "../../../../../data-access/src/lib/trailers.slice";
import styles from "./trailers-form.module.scss";

interface TrailersFormProps {
	formState: ITrailerRequestDto | undefined;
	onClose: () => void;
}

export const TrailersForm = ({ formState, onClose }: TrailersFormProps) => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { data: trailerTypes } = useAppSelector((state) => state.trailerTypes);
	const { data: constraints } = useAppSelector((state) => state.constraints);
	const { currentBusinessUnit } = useAppSelector(
		(state) => state.authentication,
	);
	const { loading } = useAppSelector((state) => state.trailers);

	const [trailerTypeId, setTrailerTypeId] = useState<number | undefined>(
		formState?.trailerType?.id,
	);
	const [constraintIds, setConstraintIds] = useState<number[]>([]);

	useEffect(() => {
		if (!formState) return;

		const listOfIds = [];
		for (const item of formState.constraints) {
			listOfIds.push(item.id);
		}
		setConstraintIds(listOfIds);
	}, [formState]);

	const onConstraintsDropdownChange = (event: MultiSelectChangeEvent) => {
		const selectedConstraints = event.target.value;
		setConstraintIds(selectedConstraints.map((item) => item.id));
	};

	const handleSubmit: FormProps["onSubmit"] = async (dataItem) => {
		const formData = new FormData();

		if (dataItem?.files?.length) {
			const newDataItem = { ...dataItem };
			if (newDataItem.files) {
				for (const file of newDataItem.files) {
					formData.append("files", file.getRawFile?.() || new Blob());
				}
			}
		}

		if (formState) {
			const actionResult = await dispatchWithNotifications({
				action: updateTrailerAction,
				payload: {
					...dataItem,
					trailerTypeId,
					constraintIds,
				} as ITrailerRequestDto,
				successMessage: `Trailer named ${formState.name} updated successfully.`,
				errorMessage: `Could not update trailer named ${formState.name}.`,
			});
			updateTrailerAction.fulfilled.match(actionResult) && onClose();
		} else {
			const actionResult = await dispatchWithNotifications({
				action: createTrailerAction,
				payload: {
					...dataItem,
					trailerTypeId,
					constraintIds,
				} as ITrailerRequestDto,
				successMessage: `Trailer named ${dataItem.name} added successfully.`,
				errorMessage: `Could not create trailer named ${dataItem.name}.`,
			});

			if (createTrailerAction.fulfilled.match(actionResult)) {
				if (dataItem?.files?.length > 0) {
					const actionResultAttachments = await dispatch(
						addAttachmentsAction({
							documents: formData,
							entity: AttachmentUsage.Trailer,
							entityId: actionResult.payload.id,
						}),
					);

					if (addAttachmentsAction.fulfilled.match(actionResultAttachments)) {
						dispatch(
							attachDocumentsToTrailerAction({
								documents: actionResultAttachments.payload,
								entityId: actionResult.payload.id,
							}),
						);
					}
				}
				onClose();
			}
		}
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formState}
			render={(formRenderProps) => (
				<FormElement>
					<div className={styles.formContainer}>
						<div>
							<CelerumDisabledInput
								value={currentBusinessUnit?.name}
								label="Business Unit"
							/>
							<Field
								id="name"
								name="name"
								label="Name"
								component={CelerumFormInput}
								type="text"
								maxLength={100}
								validator={requiredValidator}
								required
								focused="true"
							/>
							<Field
								id="trailerType"
								name="trailerType"
								dataItemKey="id"
								textField="name"
								label="Type"
								component={CelerumFormDropDownList}
								onChange={(event) => setTrailerTypeId(event.target.value.id)}
								data={trailerTypes}
								validator={requiredValidator}
								required
							/>
							<Field
								id="chassisNo"
								name="chassisNo"
								label="Chassis No."
								maxLength={40}
								component={CelerumFormInput}
								type="text"
							/>
							<Field
								id="constraints"
								name="constraints"
								data={constraints}
								dataItemKey="id"
								textField="name"
								label="Constraints Satisfied"
								component={CelerumFormMultiSelect}
								onChange={onConstraintsDropdownChange}
							/>
							{!formState && (
								<Field
									label="Upload Documents"
									id="files"
									name="files"
									component={CelerumFormUploadInput}
								/>
							)}
						</div>
						<div>
							<Field
								id="nextMOTDate"
								name="nextMOTDate"
								label="Next MOT Date"
								component={CelerumFormDatePicker}
							/>
							<Field
								id="nextInspectionDate"
								name="nextInspectionDate"
								label="Next Inspection Date"
								component={CelerumFormDatePicker}
							/>
							<Field
								id="European Registration No."
								name="europeanRegistrationNumber"
								label="European Registration No."
								maxLength={40}
								component={CelerumFormInput}
								type="text"
							/>
							<Field
								id="Fleet No."
								name="fleetNumber"
								label="Fleet No."
								maxLength={40}
								component={CelerumFormInput}
								type="text"
							/>
							<Field
								id="Ministry No."
								name="ministryNumber"
								label="Ministry No."
								maxLength={40}
								component={CelerumFormInput}
								type="text"
							/>
							<Field
								id="notes"
								name="notes"
								label="Notes"
								component={CelerumFormTextArea}
								maxLength={500}
								type="textarea"
							/>
						</div>
					</div>
					<CelerumSubmitButton
						type="submit"
						disabled={!formRenderProps.allowSubmit || loading}
						title="Submit"
					/>
				</FormElement>
			)}
		/>
	);
};

import { Reveal } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import {
	ExpansionPanel,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import {
	arrowsAxesIcon,
	chevronDownIcon,
	chevronUpIcon,
	pencilIcon,
	printIcon,
	trashIcon,
} from "@progress/kendo-svg-icons";
import { useMemo, useState } from "react";
import type { JobDetailsResponse } from "../../api/JobApi";
import { DFlex } from "../../display/DFlex";
import { DGrid } from "../../display/DGrid";
import { toCurrency } from "../../helpers";
import { DTag } from "../../helpersReact";
import { JobDetailsCard } from "./JobDetailsCard";
import { JobDetailsDisplayLocation } from "./JobDetailsDisplayLocation";
import { JobDetailsInformationCard } from "./JobDetailsInformationCard";

type JobDetailsGoodsProps = {
	data: JobDetailsResponse;
	onSplitGoodsClicked: () => void;
	onEditGoodClicked: (id: number) => void;
	onRemoveGoodClicked: (id: number) => void;
	onGenerateCMRClicked: (id: number) => void;
	onGenerateCargoLabelClicked: (id: number) => void;
};
export const JobDetailsGoods = ({
	data,
	onSplitGoodsClicked,
	onEditGoodClicked,
	onRemoveGoodClicked,
	onGenerateCMRClicked,
	onGenerateCargoLabelClicked,
}: JobDetailsGoodsProps) => {
	const [expanded, setExpanded] = useState<number>();
	const totalRevenue = useMemo(
		() => data.goods.reduce((acc, x) => acc + x.revenue, 0),
		[data.goods],
	);
	return (
		<JobDetailsCard
			title={`Goods (${data.goods.length}) - ${toCurrency(
				totalRevenue,
				data.currencyCode,
			)}`}
			buttons={
				<Button
					svgIcon={arrowsAxesIcon}
					themeColor="primary"
					onClick={onSplitGoodsClicked}
				>
					Split Goods
				</Button>
			}
		>
			{data.goods.map((x) => {
				const hasLocation = x.collectionLocation && x.deliveryLocation;
				return (
					<div key={x.id}>
						<ExpansionPanel
							title={
								<DFlex spaceBetween center>
									<DFlex center>
										<DFlex column noGap>
											<DFlex center>
												<b>{x.name}</b>
											</DFlex>
											<div>
												{hasLocation && (
													<>
														{x.collectionLocation.name} {">>"}{" "}
														{x.deliveryLocation.name}
													</>
												)}
											</div>
										</DFlex>
									</DFlex>
									<DFlex gap="8px" center>
										<DTag>{toCurrency(x.revenue, x.currencyCode)}</DTag>
										<Button
											svgIcon={pencilIcon}
											onClick={(e) => {
												e.stopPropagation();
												onEditGoodClicked(x.id);
											}}
										/>
										<Button
											svgIcon={trashIcon}
											onClick={(e) => {
												e.stopPropagation();
												onRemoveGoodClicked(x.id);
											}}
										/>
									</DFlex>
								</DFlex>
							}
							expanded={expanded === x.id}
							onAction={() => setExpanded(x.id === expanded ? undefined : x.id)}
						>
							<Reveal>
								{expanded === x.id && (
									<ExpansionPanelContent>
										<DGrid columns="repeat(2, 1fr)" gap="8px">
											<JobDetailsInformationCard
												icon={chevronUpIcon}
												title="Start Location"
												value={
													<JobDetailsDisplayLocation
														{...x.collectionLocation}
													/>
												}
											/>
											<JobDetailsInformationCard
												icon={chevronDownIcon}
												title="End Location"
												value={
													<JobDetailsDisplayLocation {...x.deliveryLocation} />
												}
											/>
										</DGrid>
										<DFlex spaceBetween>
											Goods ID: <b>{x.uniqueId}</b>
										</DFlex>
										<DFlex spaceBetween>
											Special Requirements:{" "}
											<b>
												{x.constraints.length
													? x.constraints.map((c) => c.name).join(", ")
													: "-"}
											</b>
										</DFlex>
										<DFlex spaceBetween>
											Revenue: <b>{toCurrency(x.revenue, data.currencyCode)}</b>
										</DFlex>
										<DFlex spaceBetween>
											Quantity: <b>{x.quantity}</b>
										</DFlex>
										<DFlex spaceBetween>
											Weight: <b>{x.weight} kg</b>
										</DFlex>
										<DFlex spaceBetween>
											Notes: <b>{x.notes}</b>
										</DFlex>
										<DFlex flexEnd>
											<Button
												svgIcon={printIcon}
												themeColor="primary"
												onClick={() => onGenerateCMRClicked(x.id)}
											>
												Generate CMR
											</Button>
											<Button
												svgIcon={printIcon}
												themeColor="primary"
												onClick={() => onGenerateCargoLabelClicked(x.id)}
											>
												Generate Cargo Label
											</Button>
										</DFlex>
									</ExpansionPanelContent>
								)}
							</Reveal>
						</ExpansionPanel>
					</div>
				);
			})}
		</JobDetailsCard>
	);
};

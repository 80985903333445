import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import {
	NEW_USER_STATE,
	USERS_FEATURE_KEY,
} from "../../../../common/models/src/lib/constants/user.constants";
import { ErrorCode } from "../../../../common/models/src/lib/enums/error-codes.enum";
import type { IBaseWithIdString } from "../../../../common/models/src/lib/interfaces/base.interface";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	IUserRequestDto,
	IUserResponseDto,
	UserState,
} from "../../../../common/models/src/lib/interfaces/user.interface";
import {
	generateErrorCode,
	generateErrorMessage,
} from "../../../../common/utils/src/lib/helpers/error.helpers";
import {
	createUser,
	deleteUser,
	fetchRoles,
	fetchUsers,
	updateUser,
} from "./users-data-access";

const initialState: UserState = {
	data: [],
	roles: [],
	total: 0,
	errorCode: ErrorCode.DEFAULT,
	createdUserSuccess: false,
	aggregateResults: null,
	loading: false,
	errors: null,
	message: "",
};

export const fetchUsersAction = createAsyncThunk(
	"users/fetchUsers",
	(params: CelerumQueryParams = {}) => {
		return fetchUsers(params);
	},
);

export const deleteUserAction = createAsyncThunk(
	"users/deleteUser",
	(id: string | number) => {
		return deleteUser(id);
	},
);

export const createUserAction = createAsyncThunk(
	"users/createUser",
	(user: IUserRequestDto) => {
		return createUser(user);
	},
);

export const updateUserAction = createAsyncThunk(
	"users/updateUser",
	(user: IUserRequestDto) => {
		return updateUser(user);
	},
);

export const fetchRolesAction = createAsyncThunk("users/fetchRoles", () => {
	return fetchRoles();
});

const usersSlice = createSlice({
	name: USERS_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		resetCreatedUserSuccess: (state: UserState) => {
			state.createdUserSuccess = false;
		},
		resetUsersErrorCode: (state: UserState) => {
			state.errorCode = ErrorCode.DEFAULT;
		},
		clearUsersAction: (state: UserState) => ({
			...initialState,
			roles: state.roles,
		}),
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchUsersAction.pending, (state: UserState) => {
				state.loading = true;
			})
			.addCase(deleteUserAction.pending, (state: UserState) => {
				state.loading = true;
			})
			.addCase(updateUserAction.pending, (state: UserState) => {
				state.loading = true;
			})
			.addCase(createUserAction.pending, (state: UserState) => {
				state.loading = true;
			})
			.addCase(fetchRolesAction.pending, (state: UserState) => {
				state.loading = true;
			})
			/** Fulfilled */
			.addCase(
				fetchUsersAction.fulfilled,
				(state: UserState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults, page, pageSize } =
						action.payload;
					const users = data as IUserResponseDto[];

					/** Check if the data is already fetched
					 * If it is, just update the data
					 * If it is not, fill the array with NEW_USER_STATE
					 */
					const newUsers =
						state.data.length === total
							? [...state.data]
							: new Array(total).fill(NEW_USER_STATE);

					/** Fill newUsers with the fetched data */
					users.forEach((user, i) => {
						if (page && pageSize) {
							const index = (page - 1) * pageSize + i;
							newUsers[index] = {
								...newUsers[index],
								...user,
							};
						}
					});

					state.total = total;
					state.data = newUsers;
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			.addCase(
				deleteUserAction.fulfilled,
				(state: UserState, action: PayloadAction<number | string | null>) => {
					state.data = state.data.filter((user) => user.id !== action.payload);
					state.loading = false;
					state.total -= 1;
				},
			)
			.addCase(
				createUserAction.fulfilled,
				(state: UserState, action: PayloadAction<IUserResponseDto>) => {
					state.data = [action.payload, ...state.data];
					state.loading = false;
					state.total += 1;
					state.createdUserSuccess = true;
				},
			)
			.addCase(
				updateUserAction.fulfilled,
				(state: UserState, action: PayloadAction<IUserResponseDto>) => {
					const index = state.data.findIndex(
						(user) => user.id === action.payload.id,
					);
					state.data[index] = action.payload;
					state.loading = false;
				},
			)
			.addCase(
				fetchRolesAction.fulfilled,
				(state: UserState, action: PayloadAction<CelerumResponse>) => {
					const { data } = action.payload;
					state.roles = data as IBaseWithIdString[];
					state.loading = false;
				},
			)
			/** Rejected */
			.addCase(fetchUsersAction.rejected, (state: UserState) => {
				state.loading = false;
			})
			.addCase(deleteUserAction.rejected, (state: UserState) => {
				state.loading = false;
			})
			.addCase(createUserAction.rejected, (state: UserState, action) => {
				state.loading = false;
				state.errorCode = generateErrorCode(action.error.code);
				state.message = generateErrorMessage(state.errorCode);
			})
			.addCase(updateUserAction.rejected, (state: UserState) => {
				state.loading = false;
			})
			.addCase(fetchRolesAction.rejected, (state: UserState) => {
				state.loading = false;
			});
	},
});

/** Reducer */
export const usersReducer = usersSlice.reducer;

/** Actions */
export const { resetUsersErrorCode, resetCreatedUserSuccess } =
	usersSlice.actions;

import NProgress from "nprogress";
import { useEffect, useState } from "react";
import { TRAILER_TYPES_GRID_COLUMNS } from "../../../../common/models/src/lib/constants/grid-column.constants";
import {
	ModalSize,
	ModalType,
} from "../../../../common/models/src/lib/enums/modal.enums";
import type { ITrailerType } from "../../../../common/models/src/lib/interfaces/trailer-type.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumGridHeader } from "../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumModal } from "../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import {
	clearTrailerTypesAction,
	deleteTrailerTypeAction,
	fetchTrailerTypesAction,
} from "../../../data-access/src/lib/trailer-types.slice";
import { TrailerTypeForm } from "./components/trailer-types-form/trailer-types-form";

export const TrailerTypesFeature = () => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { data, total, loading } = useAppSelector(
		(state) => state.trailerTypes,
	);

	const [showModal, setShowModal] = useState<{
		createOrUpdate: boolean;
		delete: boolean;
	}>({
		createOrUpdate: false,
		delete: false,
	});
	const [selectedTrailerType, setSelectedTrailerType] = useState<
		ITrailerType | undefined
	>(undefined);

	const openCreateModal = () => {
		setShowModal({ ...showModal, createOrUpdate: true });
	};

	const openUpdateModal = (trailerType: ITrailerType) => {
		setShowModal({ ...showModal, createOrUpdate: true });
		setSelectedTrailerType(trailerType);
	};

	const openDeleteModal = (trailerType: ITrailerType) => {
		setShowModal({ ...showModal, delete: true });
		setSelectedTrailerType(trailerType);
	};

	const closeModal = () => {
		setShowModal({ createOrUpdate: false, delete: false });
		setSelectedTrailerType(undefined);
	};

	const handleDeleteTrailerType = () => {
		if (!selectedTrailerType) return;

		dispatchWithNotifications({
			action: deleteTrailerTypeAction,
			payload: selectedTrailerType.id,
			successMessage: `Trailer type named ${selectedTrailerType.name} was successfully deleted.`,
			errorMessage: `Could not delete trailer type named ${selectedTrailerType.name}.`,
		});
		setSelectedTrailerType(undefined);
	};

	const requestSortedData = (sort: string) => {
		dispatchWithNotifications({
			action: fetchTrailerTypesAction,
			payload: { sort },
			errorMessage: "Could not sort trailer types.",
		});
	};

	useEffect(() => {
		if (loading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading]);

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchTrailerTypesAction,
			payload: {},
			errorMessage: "Could not fetch trailer types.",
		});

		return () => {
			dispatch(clearTrailerTypesAction());
		};
	}, [dispatch, dispatchWithNotifications]);

	return (
		<>
			<CelerumGridHeader
				title="Trailer Types"
				numberOfItems={total}
				addButtonName="Add Trailer Type"
				addButtonDisabled={loading}
				handleOpenAddModal={openCreateModal}
			/>
			<CelerumGrid
				columns={TRAILER_TYPES_GRID_COLUMNS}
				data={data}
				total={total}
				handleUpdate={openUpdateModal}
				handleDelete={openDeleteModal}
				requestSortedData={requestSortedData}
			/>
			<CelerumModal
				title={
					selectedTrailerType
						? selectedTrailerType.name
						: "Add New Trailer Type"
				}
				width={ModalSize.Small}
				toggleDialog={closeModal}
				visible={showModal.createOrUpdate}
			>
				<TrailerTypeForm formState={selectedTrailerType} onClose={closeModal} />
			</CelerumModal>
			<CelerumConfirmModal
				type={ModalType.Delete}
				subtitle="The trailer type will be completely removed and cannot be recovered."
				entityName="trailer type"
				entityProperty="named"
				entityValue={selectedTrailerType?.name}
				isOpen={showModal.delete}
				handleSubmit={handleDeleteTrailerType}
				handleClose={closeModal}
			/>
		</>
	);
};

import {
	NEW_TRUCK_STATE,
	TRUCKS_FEATURE_KEY,
} from "../../../../common/models/src/lib/constants/truck.constants";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	IDocument,
	IEntityDocumentDto,
} from "../../../../common/models/src/lib/interfaces/document.interface";
import type {
	ITruckRequestDto,
	ITruckResponseDto,
	TruckState,
} from "../../../../common/models/src/lib/interfaces/truck.interface";
import {
	createTruck,
	deleteTruck,
	fetchTrucks,
	updateTruck,
} from "./trucks-data-access";

import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";

const initialState: TruckState = {
	data: [],
	total: 0,
	aggregateResults: null,
	loading: false,
	errors: null,
};

export const fetchTrucksAction = createAsyncThunk(
	"trucks/fetchTrucks",
	(params: CelerumQueryParams = {}) => {
		return fetchTrucks(params);
	},
);

export const createTruckAction = createAsyncThunk(
	"trucks/createTruck",
	(truck: ITruckRequestDto) => {
		return createTruck(truck);
	},
);

export const updateTruckAction = createAsyncThunk(
	"trucks/updateTruck",
	(truck: ITruckRequestDto) => {
		return updateTruck(truck);
	},
);

export const deleteTruckAction = createAsyncThunk(
	"trucks/deleteTruck",
	(id: number) => {
		return deleteTruck(id);
	},
);

const trucksSlice = createSlice({
	name: TRUCKS_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		attachDocumentsToTruckAction: (
			state: TruckState,
			action: PayloadAction<IEntityDocumentDto>,
		) => {
			state.data = state.data.map((item: ITruckResponseDto) => {
				if (item.id === action.payload.entityId) {
					item.documents = [...item.documents, ...action.payload.documents];
				}
				return item;
			});
		},
		deleteDocumentsFromTruckAction: (
			state: TruckState,
			action: PayloadAction<number>,
		) => {
			state.data = state.data.map((item: ITruckResponseDto) => {
				item.documents = item.documents.filter(
					(document: IDocument) => document.id !== action.payload,
				);

				return item;
			});
		},
		clearTrucksAction: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchTrucksAction.pending, (state: TruckState) => {
				state.loading = true;
			})
			.addCase(deleteTruckAction.pending, (state: TruckState) => {
				state.loading = true;
			})
			.addCase(createTruckAction.pending, (state: TruckState) => {
				state.loading = true;
			})
			.addCase(updateTruckAction.pending, (state: TruckState) => {
				state.loading = true;
			})
			/** Fulfilled */
			.addCase(
				fetchTrucksAction.fulfilled,
				(state: TruckState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults, page, pageSize } =
						action.payload;
					const trucks = data as ITruckResponseDto[];

					/** Check if the data is already fetched
					 * If it is, just update the data
					 * If it is not, fill the array with NEW_TRUCK_STATE
					 */
					const newTrucks =
						state.data.length === total
							? [...state.data]
							: new Array(total).fill(NEW_TRUCK_STATE);

					/** Fill newTrucks with the fetched data */
					trucks.forEach((truck, i) => {
						if (page && pageSize) {
							const index = (page - 1) * pageSize + i;
							newTrucks[index] = {
								...newTrucks[index],
								...truck,
							};
						}
					});

					state.total = total;
					state.data = newTrucks;
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			.addCase(
				createTruckAction.fulfilled,
				(state: TruckState, action: PayloadAction<ITruckResponseDto>) => {
					state.data = [action.payload, ...state.data];
					state.loading = false;
					state.total += 1;
				},
			)
			.addCase(
				updateTruckAction.fulfilled,
				(state: TruckState, action: PayloadAction<ITruckResponseDto>) => {
					const index = state.data.findIndex(
						(truck) => truck.id === action.payload.id,
					);
					state.data[index] = action.payload;
					state.loading = false;
				},
			)
			.addCase(
				deleteTruckAction.fulfilled,
				(state: TruckState, action: PayloadAction<number | null>) => {
					state.data = state.data.filter(
						(truck) => truck.id !== action.payload,
					);
					state.loading = false;
					state.total -= 1;
				},
			)
			/** Rejected */
			.addCase(fetchTrucksAction.rejected, (state: TruckState) => {
				state.loading = false;
			})
			.addCase(createTruckAction.rejected, (state: TruckState) => {
				state.loading = false;
			})
			.addCase(updateTruckAction.rejected, (state: TruckState) => {
				state.loading = false;
			})
			.addCase(deleteTruckAction.rejected, (state: TruckState) => {
				state.loading = false;
			});
	},
});

/** Reducer */
export const trucksReducer = trucksSlice.reducer;

/** Actions */
export const {
	attachDocumentsToTruckAction,
	deleteDocumentsFromTruckAction,
	clearTrucksAction,
} = trucksSlice.actions;

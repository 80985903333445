import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTitle } from "react-use";
import { JOB_STATUS_MAPPINGS } from "../../../libs/common/models/src/lib/constants/job.constants";
import { NOT_AVAILABLE } from "../../../libs/common/models/src/lib/constants/messages.constants";
import { CelerumActions } from "../../../libs/common/models/src/lib/enums/actions.enum";
import { JobStatus } from "../../../libs/common/models/src/lib/enums/job.enum";
import { ModalType } from "../../../libs/common/models/src/lib/enums/modal.enums";
import {
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../libs/common/stores/src/lib/utils";
import {
	CelerumButton,
	CelerumWhiteButton,
} from "../../../libs/common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumConfirmModal } from "../../../libs/common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumDetailsHeader } from "../../../libs/common/ui/src/lib/components/celerum-details-header/celerum-details-header.component";
import { generateCreatedByText } from "../../../libs/common/utils/src/lib/helpers/date.helpers";
import { fetchGoodsByJobIdAction } from "../../../libs/goods/data-access/src/lib/goods.slice";
import { fetchInvoiceChecklistAction } from "../../../libs/invoices/data-access/src/lib/invoices.slice";
import { InvoiceJobLink } from "../../../libs/invoices/feature/src/lib/components/invoice-job-link/invoice-job-link.component";
import { canReInvoice } from "../../../libs/invoices/feature/src/lib/helpers/invoice-option.helpers";
import { useInvoiceableJobActionSelected } from "../../../libs/invoices/feature/src/lib/hooks/use-invoiceable-job-action-selected.hook";
import { InvoiceChecklistFeature } from "../../../libs/invoices/feature/src/lib/invoice-checklist-feature/invoice-checklist-feature";
import { InvoiceInformationFeature } from "../../../libs/invoices/feature/src/lib/invoice-information-feature/invoice-information-feature";
import { fetchJobByIdAction } from "../../../libs/jobs/data-access/src/lib/jobs.slice";
import styles from "./invoice-details.module.scss";

export const InvoiceDetailsPage = () => {
	const { id } = useParams();
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const navigate = useNavigate();
	const handleSelectedAction = useInvoiceableJobActionSelected();

	const { navbarExpanded, job, loading, invoiceLoading } = useAppSelector(
		(state) => ({
			navbarExpanded: state.userInterface.navbarExpanded,
			job: state.jobs.job,
			loading: state.jobs.loading,
			invoiceLoading: state.invoices.loading.invoices,
		}),
	);

	const [showModal, setShowModal] = useState<boolean>(false);
	const [selectedAction, setSelectedAction] = useState<CelerumActions | null>(
		null,
	);

	useTitle(`Invoice ${id} - ${job?.uniqueId}`);

	const renderedHeaderSubtitle = useMemo(() => {
		if (job) {
			return generateCreatedByText(
				job.createdByUser?.fullName ?? NOT_AVAILABLE,
				job.creationTime,
			);
		}
	}, [job]);

	const renderInvoiceButton = (status?: JobStatus) => {
		switch (status) {
			case JobStatus.INVOICE_FAILED:
			case JobStatus.READY_FOR_INVOICE:
			case JobStatus.READY_FOR_REINVOICE:
				return "Mark as checked";
			default:
				return "Already checked";
		}
	};

	const renderTitle = (status?: JobStatus) => {
		switch (status) {
			case JobStatus.CHECKED:
			case JobStatus.CHECKED_AGAIN:
			case JobStatus.INVOICE_GENERATED:
			case JobStatus.INVOICE_SENT:
			case JobStatus.REINVOICE_GENERATED:
			case JobStatus.REINVOICE_SENT:
				return job?.uniqueId || NOT_AVAILABLE;
			default:
				return (
					<InvoiceJobLink
						id={job?.id || 0}
						uniqueId={job?.uniqueId || NOT_AVAILABLE}
					/>
				);
		}
	};

	const getCheckedStatus = (status?: JobStatus) => {
		switch (status) {
			case JobStatus.READY_FOR_INVOICE:
			case JobStatus.INVOICE_FAILED:
				return JobStatus.CHECKED;
			case JobStatus.READY_FOR_REINVOICE:
				return JobStatus.CHECKED_AGAIN;
			default:
				return undefined;
		}
	};

	const handleMarkAsChecked = (id: number | undefined) => {
		if (id) {
			setSelectedAction(CelerumActions["Mark as Checked"]);
			setShowModal(true);
		}
	};

	const handleSendForReview = (id: number | undefined) => {
		if (id) {
			setSelectedAction(CelerumActions["Send for Review"]);
			setShowModal(true);
		}
	};

	const handleReInvoice = (id: number | undefined) => {
		if (id) {
			setSelectedAction(CelerumActions["Re-invoice"]);
			setShowModal(true);
		}
	};

	const handlePreview = (id: number | undefined) => {
		if (id) {
			setSelectedAction(CelerumActions["Preview Invoice"]);
			setShowModal(true);
		}
	};

	const handleSubmit = async () => {
		await handleSelectedAction(
			selectedAction,
			Number(id),
			getCheckedStatus(job?.status),
		);
		closeModal();
		if (
			selectedAction === CelerumActions["Mark as Checked"] ||
			selectedAction === CelerumActions["Send for Review"] ||
			selectedAction === CelerumActions["Re-invoice"]
		) {
			navigate("/invoicing2");
		}
	};

	const closeModal = () => {
		setShowModal(false);
		setSelectedAction(null);
	};

	useEffect(() => {
		if (id && !Number.isNaN(Number.parseInt(id))) {
			dispatchWithNotifications({
				action: fetchJobByIdAction,
				payload: Number(id),
				errorMessage: "Could not fetch job.",
			});
			dispatchWithNotifications({
				action: fetchGoodsByJobIdAction,
				payload: Number(id),
				errorMessage: "Could not fetch goods.",
			});
			dispatchWithNotifications({
				action: fetchInvoiceChecklistAction,
				payload: Number(id),
				errorMessage: "Could not fetch checklist.",
			});
		}
	}, [id, dispatchWithNotifications]);

	return (
		<div
			className={navbarExpanded ? styles.containerOpen : styles.containerClose}
		>
			<CelerumDetailsHeader
				loading={loading.job || invoiceLoading}
				title={renderTitle(job?.status)}
				subtitle={renderedHeaderSubtitle}
				status={JOB_STATUS_MAPPINGS[job?.status ?? 1]}
			>
				<div className={styles.buttonContainer}>
					<CelerumWhiteButton
						onClick={() => handlePreview(job?.id)}
						title="Preview"
					/>

					{job && canReInvoice(job) ? (
						<CelerumWhiteButton
							onClick={() => handleReInvoice(job?.id)}
							title="Re-invoice"
						/>
					) : (
						<CelerumWhiteButton
							style={{ width: "140px" }}
							onClick={() => handleSendForReview(job?.id)}
							title="Send for Review"
						/>
					)}

					<CelerumButton
						disabled={
							!(
								job &&
								[
									JobStatus.READY_FOR_INVOICE,
									JobStatus.READY_FOR_REINVOICE,
									JobStatus.INVOICE_FAILED,
								].includes(job?.status)
							)
						}
						onClick={() => handleMarkAsChecked(job?.id)}
						title={renderInvoiceButton(job?.status)}
					/>
				</div>
			</CelerumDetailsHeader>

			<div className={styles.cardsContainer}>
				<InvoiceInformationFeature className={styles.informationContainer} />
				<InvoiceChecklistFeature
					id={id}
					className={styles.checklistContainer}
				/>
			</div>

			<CelerumConfirmModal
				title={
					selectedAction &&
					`Are you sure you want to ${CelerumActions[selectedAction]
						?.toString()
						.toLowerCase()} this job?`
				}
				itemName={`invoiceable job with ID ${job?.uniqueId}`}
				isOpen={showModal}
				type={ModalType.Warning}
				handleSubmit={handleSubmit}
				handleClose={closeModal}
			/>
		</div>
	);
};

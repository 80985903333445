import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import { LOAD_TYPES_FEATURE_KEY } from "../../../../common/models/src/lib/constants/load-types.constants";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type {
	ILoadTypeRequestDto,
	ILoadTypeResponseDto,
	LoadTypesState,
} from "../../../../common/models/src/lib/interfaces/load-type.interface";
import {
	createLoadType,
	deleteLoadType,
	fetchLoadTypes,
	updateLoadType,
} from "./load-types-data-access";

const initialState: LoadTypesState = {
	data: [],
	total: 0,
	aggregateResults: null,
	loading: false,
	errors: null,
};

export const fetchLoadTypesAction = createAsyncThunk(
	"loadTypes/fetchLoadTypes",
	(params: CelerumQueryParams = {}) => {
		return fetchLoadTypes(params);
	},
);

export const createLoadTypeAction = createAsyncThunk(
	"loadTypes/createLoadType",
	(loadType: ILoadTypeRequestDto) => {
		return createLoadType(loadType);
	},
);

export const updateLoadTypeAction = createAsyncThunk(
	"loadTypes/updateLoadType",
	(loadType: ILoadTypeRequestDto) => {
		return updateLoadType(loadType);
	},
);

export const deleteLoadTypeAction = createAsyncThunk(
	"loadTypes/deleteLoadType",
	(id: number) => {
		return deleteLoadType(id);
	},
);

const loadTypesSlice = createSlice({
	name: LOAD_TYPES_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		clearLoadTypesAction: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchLoadTypesAction.pending, (state: LoadTypesState) => {
				state.loading = true;
			})
			.addCase(createLoadTypeAction.pending, (state: LoadTypesState) => {
				state.loading = true;
			})
			.addCase(updateLoadTypeAction.pending, (state: LoadTypesState) => {
				state.loading = true;
			})
			.addCase(deleteLoadTypeAction.pending, (state: LoadTypesState) => {
				state.loading = true;
			})
			/** Fulfilled */
			.addCase(
				fetchLoadTypesAction.fulfilled,
				(state: LoadTypesState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults } = action.payload;
					state.data = data as ILoadTypeResponseDto[];
					state.total = total;
					state.aggregateResults = aggregateResults;
					state.loading = false;
				},
			)
			.addCase(
				createLoadTypeAction.fulfilled,
				(
					state: LoadTypesState,
					action: PayloadAction<ILoadTypeResponseDto>,
				) => {
					state.data = [action.payload, ...state.data];
					state.loading = false;
					state.total += 1;
				},
			)
			.addCase(
				updateLoadTypeAction.fulfilled,
				(
					state: LoadTypesState,
					action: PayloadAction<ILoadTypeResponseDto>,
				) => {
					const index = state.data.findIndex(
						(loadType) => loadType.id === action.payload.id,
					);
					state.data[index] = action.payload;
					state.loading = false;
				},
			)
			.addCase(
				deleteLoadTypeAction.fulfilled,
				(state: LoadTypesState, action: PayloadAction<number | null>) => {
					state.data = state.data.filter(
						(loadType) => loadType.id !== action.payload,
					);
					state.loading = false;
					state.total -= 1;
				},
			)
			/** Rejected */
			.addCase(fetchLoadTypesAction.rejected, (state: LoadTypesState) => {
				state.loading = false;
			})
			.addCase(createLoadTypeAction.rejected, (state: LoadTypesState) => {
				state.loading = false;
			})
			.addCase(updateLoadTypeAction.rejected, (state: LoadTypesState) => {
				state.loading = false;
			})
			.addCase(deleteLoadTypeAction.rejected, (state: LoadTypesState) => {
				state.loading = false;
			});
	},
});

/** Reducer */
export const loadTypesReducer = loadTypesSlice.reducer;

/** Actions */
export const { clearLoadTypesAction } = loadTypesSlice.actions;

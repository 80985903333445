import { CelerumActions } from "../../../../../common/models/src/lib/enums/actions.enum";
import { JobStatus } from "../../../../../common/models/src/lib/enums/job.enum";
import { useAppDispatchWithNotifications } from "../../../../../common/stores/src/lib/utils";
import {
	cancelJobAction,
	changeJobStatusAction,
	deleteJobAction,
	forceCompleteAction,
	generateFileFrontAction,
	pauseJobAction,
	resumeJobAction,
} from "../../../../data-access/src/lib/jobs.slice";

export const useJobActionSelected = () => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	return async (
		action: CelerumActions | null,
		id: number | null,
		uniqueId?: string,
	) => {
		if (!id || !action) {
			return;
		}

		switch (action) {
			case +CelerumActions["Send to invoice"]: {
				await dispatchWithNotifications({
					action: changeJobStatusAction,
					payload: { id, status: JobStatus.READY_FOR_INVOICE },
					successMessage: `Job with ID ${
						uniqueId ?? id
					} sent to invoicing successfully.`,
					errorMessage: `Could not send job with ID ${
						uniqueId ?? id
					} to invoicing.`,
				});
				break;
			}
			case +CelerumActions["Force Complete"]: {
				await dispatchWithNotifications({
					action: forceCompleteAction,
					payload: id,
					successMessage: `Job with ID ${
						uniqueId ?? id
					} was successfully forced to complete.`,
					errorMessage: `Could not force to complete the job with ID ${
						uniqueId ?? id
					}`,
				});
				break;
			}
			case +CelerumActions["Generate File Front"]: {
				await dispatchWithNotifications({
					action: generateFileFrontAction,
					payload: id,
					errorMessage: `Could not generate file front for job with ID ${
						uniqueId ?? id
					}.`,
				});
				break;
			}
			case +CelerumActions.Pause: {
				await dispatchWithNotifications({
					action: pauseJobAction,
					payload: id,
					successMessage: `Job with ID ${uniqueId ?? id} paused successfully.`,
					errorMessage: `Could not pause job with ID ${uniqueId ?? id}.`,
				});
				break;
			}
			case +CelerumActions.Resume: {
				await dispatchWithNotifications({
					action: resumeJobAction,
					payload: id,
					successMessage: `Job with ID ${uniqueId ?? id} resumed successfully!`,
					errorMessage: `Could not resume job with ID ${uniqueId ?? id}.`,
				});
				break;
			}
			case +CelerumActions.Cancel: {
				await dispatchWithNotifications({
					action: cancelJobAction,
					payload: id,
					successMessage: `Job with ID ${
						uniqueId ?? id
					} cancelled successfully.`,
					errorMessage: `Could not cancel job with ID ${uniqueId ?? id}.`,
				});
				break;
			}
			case +CelerumActions.Delete: {
				await dispatchWithNotifications({
					action: deleteJobAction,
					payload: id,
					successMessage: `Job with ID ${uniqueId ?? id} deleted successfully.`,
					errorMessage: `Could not delete job with ID ${uniqueId ?? id}.`,
				});
				break;
			}
			case +CelerumActions["Send to Invoicing Check"]: {
				await dispatchWithNotifications({
					action: changeJobStatusAction,
					payload: { id, status: JobStatus.READY_FOR_INVOICE },
					successMessage: `Job with ID ${
						uniqueId ?? id
					} sent to invoicing check successfully.`,
					errorMessage: `Could not send job with ID ${
						uniqueId ?? id
					} to invoicing check.`,
				});
				break;
			}
		}
	};
};

import { Field, Form, FormElement } from "@progress/kendo-react-form";
import cls from "classnames";
import { type ComponentProps, useEffect, useRef, useState } from "react";
import { PasswordFormState } from "../../../../../../common/models/src/lib/enums/password-form-state.enum";
import type { PasswordErrorInterface } from "../../../../../../common/models/src/lib/interfaces/password-error.interface";
import { useAppSelector } from "../../../../../../common/stores/src/lib/utils";
import { CelerumButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumPasswordInput } from "../../../../../../common/ui/src/lib/components/celerum-input/celerum-input.component";
import {
	isEqualValidator,
	passwordValidators,
} from "../../../../../../common/utils/src/lib/validators/validators";
import { PasswordMessageContainer } from "./password-message-container";

type FormProps = ComponentProps<typeof Form>;

interface ResetPasswordFormProps {
	handleSubmit: FormProps["onSubmit"];
	styles: Record<string, string>;
}

export const ResetPasswordForm = ({
	handleSubmit,
	styles,
}: ResetPasswordFormProps) => {
	const { isMobile } = useAppSelector((state) => state.userInterface);
	const updateCount = useRef<number>(0);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isFormValid, setIsFormValid] = useState(PasswordFormState.Inactive);
	const [errorMessages, setErrorMessages] = useState<PasswordErrorInterface[]>(
		/**
		 * The displayed error messages are set by applying the password validator functions
		 * on the password and confirm password fields. We apply the empty fields to the function,
		 * to get the messages.
		 */
		passwordValidators
			.map((validatorFunction) => validatorFunction(""))
			.concat(isEqualValidator("", "")),
	);
	useEffect(() => {
		/**
		 * We need to skip the validation effect for the initial rendering, in order to display the
		 * error messages in their "default" state.
		 * Because of Strict mode, forcing the skipping of the initial render doesn't work
		 * with just a boolean value, as the effect is run twice.
		 */
		if (updateCount.current < 2) {
			updateCount.current += 1;
			return;
		}

		const newErrorMessages = passwordValidators.map((f) => f(password));
		newErrorMessages.push(isEqualValidator(password, confirmPassword));
		setIsFormValid(
			newErrorMessages.every((message) => message.valid)
				? PasswordFormState.Valid
				: PasswordFormState.Invalid,
		);
		setErrorMessages(newErrorMessages);
	}, [password, confirmPassword]);

	return (
		<Form
			onSubmit={handleSubmit}
			render={() => {
				return (
					<FormElement className={cls(isMobile ? styles.element : "")}>
						<div className={styles.formContainer}>
							<Field
								name="password"
								component={CelerumPasswordInput}
								label="Password"
								onChange={(e) => setPassword(e.target.value)}
								type="password"
							/>
							<Field
								name="confirmPassword"
								label="Confirm password"
								component={CelerumPasswordInput}
								onChange={(e) => setConfirmPassword(e.target.value)}
								type="confirmPassword"
							/>
						</div>
						<div>
							<PasswordMessageContainer
								styles={styles}
								errorMessages={errorMessages}
								isFormValid={isFormValid}
							/>
							<div className={styles.buttonContainer}>
								<CelerumButton
									style={{ width: "100%" }}
									type="submit"
									disabled={!(isFormValid === PasswordFormState.Valid)}
									title="Send"
								/>
							</div>
						</div>
					</FormElement>
				);
			}}
		/>
	);
};

import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, array, date, number, object, string } from "yup";
import { useDocumentsFormDialog } from "../dialogs/useDocumentsFormDialog";
import { DFlex } from "../display/DFlex";
import {
	GenericForm,
	IDate,
	IMultiSelect,
	ISelect,
	IText,
	ITextArea,
	type LoadOptionsFn,
} from "../forms/GenericForm";
import {
	AttachmentUsageType,
	type TypedGridColumnProps,
	jobApi,
	noInvalidDate,
	toDateString,
	toDatetimeString,
	toasted,
	useLookupConstraints,
	useLookupSubcontractors,
	useLookupTrailerStatuses,
	useLookupTrailerTypes,
} from "../helpers";
import { toCell } from "../helpersReact";
import { GenericPage } from "./GenericPage";

const TrailerSchema = object({
	id: number().label("ID"),
	name: string().required().label("Name"),
	chassisNo: string().label("Chassis No"),
	constraintIds: array()
		.of(number().required().label("Constraint"))
		.label("Constraints"),
	subcontractorId: number().nullable().label("Subcontractor"),
	europeanRegistrationNumber: string().label("European Registration"),
	fleetNumber: string().label("Fleet Number"),
	ministryNumber: string().label("Ministry Number"),
	nextInspectionDate: date()
		.transform(noInvalidDate)
		.label("Next Inspection Date"),
	nextMOTDate: date().transform(noInvalidDate).label("Next MOT Date"),
	notes: string().label("Notes"),
	trailerTypeId: number().required().label("Type"),
});

type TrailerFormObject = InferType<typeof TrailerSchema>;

type TrailerFormProps = {
	defaultValues?: Partial<TrailerFormObject>;
	onSubmit: (data: TrailerFormObject) => void;
	lSubcontractors: LoadOptionsFn;
	lTrailerTypes: LoadOptionsFn;
	lConstraints: LoadOptionsFn;
	lTrailerStatuses: LoadOptionsFn;
};

const TrailerForm = ({
	defaultValues,
	onSubmit,
	lSubcontractors,
	lTrailerTypes,
	lConstraints,
}: TrailerFormProps) => {
	const form = useForm<TrailerFormObject>({
		resolver: yupResolver(TrailerSchema),
		defaultValues,
	});

	return (
		<GenericForm
			form={form}
			schema={TrailerSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<IText n="name" />
					<ISelect n="trailerTypeId" l={lTrailerTypes} />
					<IText n="europeanRegistrationNumber" />
					<IText n="fleetNumber" />
					<IText n="ministryNumber" />
					<IMultiSelect n="constraintIds" l={lConstraints} />
				</div>
				<div>
					<IText n="chassisNo" />
					<IDate n="nextInspectionDate" />
					<IDate n="nextMOTDate" />
					<ITextArea n="notes" />
					<ISelect n="subcontractorId" l={lSubcontractors} />
				</div>
			</DFlex>
		</GenericForm>
	);
};

const TrailerFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<TrailerFormProps, "onSubmit" | "defaultValues">) => (
	<TrailerForm
		defaultValues={defaultValues}
		lSubcontractors={useLookupSubcontractors()}
		lConstraints={useLookupConstraints()}
		lTrailerStatuses={useLookupTrailerStatuses()}
		lTrailerTypes={useLookupTrailerTypes()}
		onSubmit={async (data) => {
			const { id, ...rest } = data;
			const other = {
				nextInspectionDate: rest.nextInspectionDate?.toISOString(),
				nextMOTDate: rest.nextMOTDate?.toISOString(),
			};
			const processData = async () => {
				if (id) await jobApi.trailer.trailerUpdate({ id, ...rest, ...other });
				else await jobApi.trailer.trailerCreate({ ...rest, ...other });
				onSubmit(data);
			};
			toasted(processData(), id ? "Updating Trailer" : "Creating Trailer");
		}}
	/>
);

type Trailer = TrailerFormObject & {
	id: number;
	constraintsString: string;
	subcontractorString: string;
	trailerTypeString: string;
	nextInspectionDateString: string;
	nextMOTDateString: string;
};

const defaultColumns: TypedGridColumnProps<Trailer>[] = [
	{ field: "name", title: "Name" },
	{ field: "chassisNo", title: "Chassis No" },
	{ field: "constraintsString", title: "Constraints" },
	{ field: "subcontractorString", title: "Subcontractor" },
	{ field: "europeanRegistrationNumber", title: "European Registration" },
	{ field: "fleetNumber", title: "Fleet Number" },
	{ field: "ministryNumber", title: "Ministry Number" },
	{
		field: "nextInspectionDate",
		title: "Next Inspection Date",
		cell: ({ dataItem }) => toCell(dataItem.nextInspectionDateString),
	},
	{
		field: "nextMOTDate",
		title: "Next MOT Date",
		cell: ({ dataItem }) => toCell(dataItem.nextMOTDateString),
	},
	{ field: "notes", title: "Notes" },
	{ field: "trailerTypeString", title: "Trailer Type" },
];

const useFetchData = (): ComponentProps<
	typeof GenericPage<Trailer>
>["data"] => {
	const _trailers = useQuery({
		queryKey: ["jobApi.trailer.trailerList"],
		queryFn: () => jobApi.trailer.trailerList({}).then((x) => x.data.data),
		initialData: [],
	});

	const trailers = useMemo(
		() =>
			_trailers.data.map((x): Trailer => {
				const nextInspectionDate = dayjs(x.nextInspectionDate).toDate();
				const nextMOTDate = dayjs(x.nextMOTDate).toDate();
				return {
					id: x.id,
					name: x.name,
					chassisNo: x.chassisNo ?? "",
					constraintIds: x.constraints.map((x) => x.id),
					constraintsString: x.constraints.map((x) => x.name).join(", "),
					subcontractorId: x.subcontractor?.id ?? undefined,
					subcontractorString: x.subcontractor?.name ?? "",
					europeanRegistrationNumber: x.europeanRegistrationNumber ?? "",
					fleetNumber: x.fleetNumber ?? "",
					ministryNumber: x.ministryNumber ?? "",
					notes: x.notes ?? undefined,
					trailerTypeId: x.trailerType.id,
					trailerTypeString: x.trailerType.name ?? "",
					nextInspectionDate,
					nextInspectionDateString: toDatetimeString(nextInspectionDate),
					nextMOTDate,
					nextMOTDateString: toDateString(nextMOTDate),
				};
			}),
		[_trailers.data],
	);

	return {
		data: trailers,
		retry: _trailers.refetch,
		loading: _trailers.isFetching,
	};
};

export const TrailersPage2 = () => {
	const data = useFetchData();
	const [showDocumentsFor, documentsForm] = useDocumentsFormDialog();

	const handleDelete = (id: number) =>
		toasted(
			jobApi.trailer.trailerDelete(id).then(data.retry),
			"Deleting Trailer",
		);

	const getForm = (
		id: number | undefined,
		onSubmit: (data: TrailerFormObject) => void,
	) => {
		let defaultValues: Partial<TrailerFormObject> = {
			nextInspectionDate: dayjs().startOf("d").add(1, "y").toDate(),
			nextMOTDate: dayjs().startOf("d").add(1, "y").toDate(),
		};

		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};

		return (
			<TrailerFormWithDTO onSubmit={onSubmit} defaultValues={defaultValues} />
		);
	};

	const extraActions = useMemo(
		() => [
			{
				name: "View Documents",
				onClick: (x: Trailer) => {
					showDocumentsFor({
						entityId: x.id,
						usage: AttachmentUsageType.Trailer,
						onChange: data.retry,
					});
				},
			},
		],
		[showDocumentsFor, data.retry],
	);

	return (
		<>
			{documentsForm}
			<GenericPage
				pageTitle="Trailers"
				name="Trailer"
				data={data}
				onDelete={handleDelete}
				defaultColumns={defaultColumns}
				getForm={getForm}
				extraActions={extraActions}
			/>
		</>
	);
};

import { plusIcon } from "@progress/kendo-svg-icons";
import { useEffect, useState } from "react";
import { ModalSize } from "../../../../../common/models/src/lib/enums/modal.enums";
import { ParentType } from "../../../../../common/models/src/lib/enums/parent-type.enum";
import type { AnyLeg } from "../../../../../common/models/src/lib/types/leg.type";
import {
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumCustomCard } from "../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { CelerumLoader } from "../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { CelerumModal } from "../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import {
	fetchLegsByJobIdAction,
	fetchLegsByLoadIdAction,
} from "../../../../data-access/src/lib/legs.slice";
import { LegForm } from "../components/leg-form/leg-form.component";
import { LegsCardContainer } from "../components/legs-card-container/legs-card-container.component";
import styles from "./legs-information-feature.module.scss";

interface LegsInformationFeatureProps {
	className: string | undefined;
	parentType: ParentType;
	parentId?: string | undefined;
	isJobInvoiced?: boolean;
}

export const LegsInformationFeature = ({
	className,
	parentType,
	parentId,
	isJobInvoiced = false,
}: LegsInformationFeatureProps) => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { data, loading } = useAppSelector((state) => state.legs);

	const [showModal, setShowModal] = useState<boolean>(false);
	const [selectedLeg, setSelectedLeg] = useState<AnyLeg | undefined>(undefined);
	const [modalSize, setModalSize] = useState<ModalSize>(ModalSize.Large);

	const closeModal = () => {
		setShowModal(false);
		setSelectedLeg(undefined);
	};

	const renderTitle = () => {
		switch (parentType) {
			case ParentType.Job:
				return "Legs";
			case ParentType.Load:
				return "Load Stages";
			default:
				return "Legs";
		}
	};

	const renderButtonTitle = () => {
		switch (parentType) {
			case ParentType.Job:
				return "Add Leg";
			case ParentType.Load:
				return "Add Stage";
			default:
				return "Add Leg";
		}
	};

	const renderLegModalTitle = () => {
		if (parentType === ParentType.Job) {
			return selectedLeg ? `Leg ${selectedLeg.uniqueId}` : "Add Leg";
		}
		return selectedLeg ? `Stage ${selectedLeg.uniqueId}` : "Add Stage";
	};

	useEffect(() => {
		if (parentId && !Number.isNaN(Number.parseInt(parentId))) {
			dispatchWithNotifications({
				action:
					parentType === ParentType.Job
						? fetchLegsByJobIdAction
						: fetchLegsByLoadIdAction,
				payload: Number(parentId),
				errorMessage: "Could not fetch legs.",
			});
		}
	}, [dispatchWithNotifications, parentId, parentType]);

	return (
		<>
			<CelerumCustomCard
				buttonName={renderButtonTitle()}
				title={renderTitle()}
				className={className}
				disabled={loading || isJobInvoiced}
				buttonSvgIcon={plusIcon}
				onClick={() => setShowModal(true)}
			>
				{loading ? (
					<div className={styles.verticalWrapper}>
						<CelerumLoader visible />
					</div>
				) : Object.keys(data).length ? (
					<LegsCardContainer
						parentId={parentId}
						parentType={parentType}
						legs={data}
						isJobInvoiced={isJobInvoiced}
						setSelectedLeg={(leg) => {
							setSelectedLeg(leg);
							setShowModal(true);
						}}
					/>
				) : (
					!loading && (
						<div className={styles.verticalWrapper}>
							<div className={styles.messageWrapper}>
								<span>
									No {parentType === ParentType.Job ? "legs" : "stages"} found.
								</span>
							</div>
						</div>
					)
				)}
			</CelerumCustomCard>
			<CelerumModal
				visible={showModal}
				toggleDialog={closeModal}
				title={renderLegModalTitle()}
				width={modalSize}
			>
				<LegForm
					parentId={parentId}
					parentType={parentType}
					leg={selectedLeg}
					onClose={closeModal}
					setModalSize={setModalSize}
				/>
			</CelerumModal>
		</>
	);
};

import { Reveal } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import {
	Card,
	CardBody,
	ExpansionPanel,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useState } from "react";
import { GLOBALS } from "../_GLOBALS";
import { DFlex } from "../display/DFlex";
import { DGrid } from "../display/DGrid";
import type { Leg } from "../forms/LegForm";
import { jobApi } from "../helpers";
import { useDialog } from "../helpersReact";

type ClosestDriverRecommendationsProps = {
	locationId: number | undefined;
	onRecommendationSelected?: (recommendation: Recommendation) => void;
};
const ClosestDriverRecommendations = ({
	locationId,
	onRecommendationSelected,
}: ClosestDriverRecommendationsProps) => {
	const [expanded, setExpanded] = useState(false);
	const data = useQuery({
		queryKey: ["jobApi.bff.bffClosestDriversRecommendationCreate", locationId],
		queryFn: () => {
			if (!locationId) {
				return [];
			}
			return jobApi.bff
				.bffClosestDriversRecommendationCreate({ locationId })
				.then((x) => x.data);
		},
		initialData: [],
	});
	GLOBALS.recommendationsClosestDrivers = data.data;
	return (
		<ExpansionPanel
			expanded={expanded}
			title={
				<>
					Closest Drivers
					{data.isFetching && (
						<Loader size="small" type="infinite-spinner" themeColor="success" />
					)}
				</>
			}
			onAction={() => setExpanded((x) => !x)}
			style={{ minWidth: 600 }}
		>
			<Reveal>
				{expanded && (
					<ExpansionPanelContent>
						<DGrid>
							{data.data.map((x, index) => (
								<Card key={x.driverId}>
									<CardBody>
										<DFlex spaceBetween>
											<div>Driver:</div>
											<b>{x.driverName}</b>
										</DFlex>
										<DFlex spaceBetween>
											<div>Truck:</div>
											<b>{x.truckName ?? "-"}</b>
										</DFlex>
										<DFlex spaceBetween>
											<div>Trailer:</div>
											<b>{x.trailerName ?? "-"}</b>
										</DFlex>
										<DFlex spaceBetween>
											<div>Distance:</div>
											<b>{x.distance.toFixed(2)} km</b>
										</DFlex>
										<DFlex spaceBetween>
											<div>Latest known location date:</div>
											<b>
												{dayjs(x.latestKnownLocationDate).format(
													"YYYY-MM-DD HH:mm",
												)}
											</b>
										</DFlex>
										<DFlex flexEnd>
											<Button
												themeColor="primary"
												onClick={() =>
													onRecommendationSelected?.({
														...x,
														index,
														type: "ClosestDrivers",
													})
												}
											>
												Select
											</Button>
										</DFlex>
									</CardBody>
								</Card>
							))}
						</DGrid>
					</ExpansionPanelContent>
				)}
			</Reveal>
		</ExpansionPanel>
	);
};

type RandomDriverRecommendationsProps = {
	onRecommendationSelected?: (recommendation: Recommendation) => void;
};
const RandomDriverRecommendations = ({
	onRecommendationSelected,
}: RandomDriverRecommendationsProps) => {
	const [expanded, setExpanded] = useState(false);
	const data = useQuery({
		queryKey: ["jobApi.bff.bffRandomDriversRecommendationCreate"],
		queryFn: () =>
			jobApi.bff.bffRandomDriversRecommendationCreate().then((x) => x.data),
		initialData: [],
	});
	GLOBALS.recommendationsRandomDrivers = data.data;
	return (
		<ExpansionPanel
			expanded={expanded}
			title={
				<>
					Random Drivers
					{data.isFetching && (
						<Loader size="small" type="infinite-spinner" themeColor="success" />
					)}
				</>
			}
			onAction={() => setExpanded((x) => !x)}
			style={{ minWidth: 600 }}
		>
			<Reveal>
				{expanded && (
					<ExpansionPanelContent>
						<DGrid>
							{data.data.map((x, index) => (
								<Card key={x.driverId}>
									<CardBody>
										<DFlex spaceBetween>
											<div>Driver:</div>
											<b>{x.driverName}</b>
										</DFlex>
										<DFlex spaceBetween>
											<div>Truck:</div>
											<b>{x.truckName ?? "-"}</b>
										</DFlex>
										<DFlex spaceBetween>
											<div>Trailer:</div>
											<b>{x.trailerName ?? "-"}</b>
										</DFlex>
										<DFlex flexEnd>
											<Button
												themeColor="primary"
												onClick={() =>
													onRecommendationSelected?.({
														...x,
														index,
														type: "RandomDrivers",
													})
												}
											>
												Select
											</Button>
										</DFlex>
									</CardBody>
								</Card>
							))}
						</DGrid>
					</ExpansionPanelContent>
				)}
			</Reveal>
		</ExpansionPanel>
	);
};

type RecommendationsProps = {
	settings: string[];
	formData: Leg | undefined;
	onRecommendationSelected?: (recommendation: Recommendation) => void;
};
const Recommendations = ({
	settings,
	formData,
	onRecommendationSelected,
}: RecommendationsProps) => {
	const locationId =
		formData?.collectionLocationId ??
		formData?.deliveryLocationId ??
		formData?.storageLocation ??
		undefined;
	return (
		<div>
			{settings.includes("ClosestDrivers") && (
				<ClosestDriverRecommendations
					locationId={locationId}
					onRecommendationSelected={onRecommendationSelected}
				/>
			)}
			{settings.includes("RandomDrivers") && (
				<RandomDriverRecommendations
					onRecommendationSelected={onRecommendationSelected}
				/>
			)}
		</div>
	);
};

export type Recommendation = {
	driverId?: number | null;
	truckId?: number | null;
	trailerId?: number | null;
	subcontractorId?: number | null;
	index: number;
	type: string;
};

export const useRecommendationsDialog = (
	formData: Leg | undefined,
	onRecommendationSelected?: (recommendation: Recommendation) => void,
) => {
	const data = useQuery({
		queryKey: ["jobApi.bff.bffRecommendationSettingsList"],
		queryFn: () =>
			jobApi.bff.bffRecommendationSettingsList().then((x) => x.data),
		initialData: [],
	});
	const isEnabled = data.data.length > 0;
	const [toggleDialog, dialog] = useDialog(
		isEnabled && (
			<Recommendations
				settings={data.data}
				formData={formData}
				onRecommendationSelected={onRecommendationSelected}
			/>
		),
		"Recommendations",
	);
	return [toggleDialog, dialog, isEnabled] as const;
};

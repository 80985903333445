import NProgress from "nprogress";
import { useEffect, useMemo, useState } from "react";
import { EXCHANGE_RATE_GRID_COLUMNS } from "../../../../common/models/src/lib/constants/grid-column.constants";
import {
	ModalSize,
	ModalType,
} from "../../../../common/models/src/lib/enums/modal.enums";
import type { ICurrencyExchangeRate } from "../../../../common/models/src/lib/interfaces/currency-exchange-rate.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumGridHeader } from "../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumModal } from "../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import {
	clearCurrencyExchangeRatesAction,
	deleteCurrencyExchangeRateAction,
	fetchCurrencyExchangeRatesAction,
} from "../../../data-access/src/lib/currency-exchange-rate.slice";
import { ExchangeRatesForm } from "./components/exchange-rates-form/exchange-rates-form.component";

export const CurrencyExchangeRatesFeature = () => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const dispatch = useAppDispatch();

	const { data, total, loading } = useAppSelector(
		(state) => state.currencyExchangeRates,
	);

	const [showModal, setShowModal] = useState<{
		createOrUpdate: boolean;
		delete: boolean;
	}>({
		createOrUpdate: false,
		delete: false,
	});

	const [selectedExchangeRate, setSelectedExchangeRate] = useState<
		ICurrencyExchangeRate | undefined
	>(undefined);

	const renderedExchangeRates = useMemo(
		() =>
			data.map((exchangeRate) => ({
				...exchangeRate,
				exchangeRateText: `1 ${exchangeRate.fromCurrency?.code} = ${exchangeRate.exchangeRate} ${exchangeRate.toCurrency?.code}`,
			})),
		[data],
	);

	const getExchangeRateText = (rate: ICurrencyExchangeRate | undefined) => {
		return rate
			? `1 ${rate.fromCurrency?.code} = ${rate.exchangeRate} ${rate.toCurrency?.code}`
			: "";
	};

	const handleDeleteExchangeRate = () => {
		if (!selectedExchangeRate) return;

		dispatchWithNotifications({
			action: deleteCurrencyExchangeRateAction,
			payload: selectedExchangeRate.id,
			successMessage: `Exchange rate ${getExchangeRateText(
				selectedExchangeRate,
			)} deleted successfully.`,
			errorMessage: `Could not delete exchange rate ${getExchangeRateText(
				selectedExchangeRate,
			)}.`,
		});
		setSelectedExchangeRate(undefined);
	};

	const openCreateModal = () => {
		setShowModal({ ...showModal, createOrUpdate: true });
	};

	const openDeleteModal = (exchangeRate: ICurrencyExchangeRate) => {
		setShowModal({ ...showModal, delete: true });
		setSelectedExchangeRate(exchangeRate);
	};

	const openEditDialog = (exchangeRate: ICurrencyExchangeRate) => {
		setShowModal({ ...showModal, createOrUpdate: true });
		setSelectedExchangeRate(exchangeRate);
	};

	const closeModal = () => {
		setShowModal({ createOrUpdate: false, delete: false });
		setSelectedExchangeRate(undefined);
	};

	useEffect(() => {
		if (loading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading]);

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchCurrencyExchangeRatesAction,
			payload: {},
			errorMessage: "Could not fetch currency exchange rates.",
		});

		return () => {
			dispatch(clearCurrencyExchangeRatesAction());
		};
	}, [dispatch, dispatchWithNotifications]);

	return (
		<>
			<CelerumGridHeader
				title="Exchange Rates"
				numberOfItems={total}
				addButtonName="Add Exchange Rate"
				addButtonDisabled={loading}
				handleOpenAddModal={openCreateModal}
			/>
			<CelerumConfirmModal
				type={ModalType.Delete}
				subtitle="The exchange rate will be completely removed and cannot be recovered."
				entityName="exchange rate"
				entityProperty="of pair"
				entityValue={getExchangeRateText(selectedExchangeRate)}
				isOpen={showModal.delete}
				handleSubmit={handleDeleteExchangeRate}
				handleClose={closeModal}
			/>
			<CelerumGrid
				columns={EXCHANGE_RATE_GRID_COLUMNS}
				data={renderedExchangeRates}
				total={total}
				handleDelete={openDeleteModal}
				handleUpdate={openEditDialog}
			/>
			<CelerumModal
				title={
					selectedExchangeRate ? "Edit Exchange Rate" : "Add New Exchange Rate"
				}
				width={ModalSize.Medium}
				toggleDialog={closeModal}
				visible={showModal.createOrUpdate}
			>
				<ExchangeRatesForm
					currentPairs={data}
					formState={selectedExchangeRate}
					onClose={closeModal}
				/>
			</CelerumModal>
		</>
	);
};

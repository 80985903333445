import {
	DateRangePicker,
	type SelectionRange,
} from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { TypedDropDownButton } from "./TypedDropDownButton";
import { DFlex } from "./display/DFlex";
import { toSimpleDateString } from "./helpers";

type CalendarQuickSelect = { name: string; action: () => void };
type GenericDateRangePickerProps = {
	value: SelectionRange;
	onChange: (value: SelectionRange) => void;
};
const useDefaultCalendarQuickSelects = (
	setRangeValue: (value: SelectionRange) => void,
): CalendarQuickSelect[] =>
	useMemo(
		() => [
			{
				name: "Today",
				action: () =>
					setRangeValue({
						start: dayjs().startOf("d").toDate(),
						end: dayjs().endOf("d").toDate(),
					}),
			},
			{
				name: "Yesterday",
				action: () =>
					setRangeValue({
						start: dayjs().add(-1, "d").startOf("d").toDate(),
						end: dayjs().add(-1, "d").endOf("d").toDate(),
					}),
			},
			{
				name: "This Week",
				action: () =>
					setRangeValue({
						start: dayjs().startOf("w").toDate(),
						end: dayjs().endOf("w").toDate(),
					}),
			},
			{
				name: "Last Week",
				action: () =>
					setRangeValue({
						start: dayjs().add(-1, "w").startOf("w").toDate(),
						end: dayjs().add(-1, "w").endOf("w").toDate(),
					}),
			},
			{
				name: "This Month",
				action: () =>
					setRangeValue({
						start: dayjs().startOf("M").toDate(),
						end: dayjs().endOf("M").toDate(),
					}),
			},
			{
				name: "Last Month",
				action: () =>
					setRangeValue({
						start: dayjs().add(-1, "M").startOf("M").toDate(),
						end: dayjs().add(-1, "M").endOf("M").toDate(),
					}),
			},
			{ name: "All", action: () => setRangeValue({ start: null, end: null }) },
			{
				name: "Future",
				action: () =>
					setRangeValue({
						start: dayjs().startOf("d").toDate(),
						end: null,
					}),
			},
			{
				name: "Past",
				action: () =>
					setRangeValue({ start: null, end: dayjs().endOf("d").toDate() }),
			},
		],
		[setRangeValue],
	);
export const GenericDateRangePicker = ({
	onChange,
	value,
}: GenericDateRangePickerProps) => {
	const [rangeValue, setRangeValue] = useState<SelectionRange>(value);
	const startString = useMemo(
		() => (value.start ? toSimpleDateString(value.start) : "?"),
		[value.start],
	);
	const endString = useMemo(
		() => (value.end ? toSimpleDateString(value.end) : "?"),
		[value.end],
	);
	const startEndString = useMemo(
		() => `${startString} → ${endString}`,
		[startString, endString],
	);
	const element = (
		<Input
			value={startEndString}
			style={{ width: "150px", cursor: "pointer" }}
			readOnly
		/>
	);
	const calendarQuickSelects = useDefaultCalendarQuickSelects(setRangeValue);
	useEffect(() => void onChange(rangeValue), [onChange, rangeValue]);
	return (
		<DFlex gap="6px">
			<DateRangePicker
				value={rangeValue}
				onChange={(e) => {
					if (e.value.end) e.value.end = dayjs(e.value.end).endOf("d").toDate();
					return setRangeValue(e.value);
				}}
				startDateInput={() => element}
				endDateInput={() => undefined}
			/>
			<TypedDropDownButton
				icon="calendar"
				size="small"
				title="Quick Select Date Range"
				items={calendarQuickSelects}
				onItemClick={(e) => e.item.action()}
				itemRender={(i) => <span>{i.item.name}</span>}
				popupSettings={{ animate: false }}
			/>
		</DFlex>
	);
};

import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, number, object, string } from "yup";
import { DFlex } from "../display/DFlex";
import { GenericForm, IText } from "../forms/GenericForm";
import { type TypedGridColumnProps, jobApi, toasted } from "../helpers";
import { GenericPage } from "./GenericPage";

const ConstraintSchema = object({
	id: number().label("ID"),
	name: string().required().label("Name"),
});
type ConstraintFormObject = InferType<typeof ConstraintSchema>;

type ConstraintFormProps = {
	defaultValues?: Partial<ConstraintFormObject>;
	onSubmit: (data: ConstraintFormObject) => void;
};
const ConstraintForm = ({ defaultValues, onSubmit }: ConstraintFormProps) => {
	const form = useForm<ConstraintFormObject>({
		resolver: yupResolver(ConstraintSchema),
		defaultValues,
	});
	return (
		<GenericForm
			form={form}
			schema={ConstraintSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<IText n="name" />
				</div>
			</DFlex>
		</GenericForm>
	);
};
const ConstraintFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<ConstraintFormProps, "onSubmit" | "defaultValues">) => (
	<ConstraintForm
		defaultValues={defaultValues}
		onSubmit={async (data) => {
			const { id, ...rest } = data;
			const processData = async () => {
				if (id) await jobApi.constraint.constraintUpdate({ id, ...rest });
				else await jobApi.constraint.constraintCreate({ ...rest });
				onSubmit(data);
			};
			toasted(
				processData(),
				id ? "Updating Constraint" : "Creating Constraint",
			);
		}}
	/>
);

type Constraint = ConstraintFormObject & { id: number };
const defaultColumns: TypedGridColumnProps<Constraint>[] = [
	{ field: "name", title: "Name" },
];
const useFetchData = (): ComponentProps<
	typeof GenericPage<Constraint>
>["data"] => {
	const _constraints = useQuery({
		queryKey: ["jobApi.constraint.constraintList"],
		queryFn: () =>
			jobApi.constraint.constraintList({}).then((x) => x.data.data),
		initialData: [],
	});
	const constraints = useMemo(
		() =>
			_constraints.data.map((x): Constraint => {
				return {
					id: x.id,
					name: x.name,
				};
			}),
		[_constraints.data],
	);
	return {
		data: constraints,
		retry: _constraints.refetch,
		loading: _constraints.isFetching,
	};
};
export const ConstraintsPage2 = () => {
	const data = useFetchData();
	const handleDelete = (id: number) =>
		toasted(
			jobApi.constraint.constraintDelete(id).then(data.retry),
			"Deleting Constraint",
		);
	const getForm = (
		id: number | undefined,
		onSubmit: (data: ConstraintFormObject) => void,
	) => {
		let defaultValues: Partial<ConstraintFormObject> = {};
		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};
		return (
			<ConstraintFormWithDTO
				onSubmit={onSubmit}
				defaultValues={defaultValues}
			/>
		);
	};
	return (
		<GenericPage
			pageTitle="Constraints"
			name="Constraint"
			data={data}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
		/>
	);
};
